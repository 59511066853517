<template>
  <div class="appbackground">
    <mobileappbar header="Menu"></mobileappbar>
    <div class="navtext text-start pt-0 ml-5 mb-5">/ {{ qrName }}</div>

    <div class="text-center" v-if="inventorytypes.length > 0">
      <div class="infotext text-center mb-5">Select item to create a fault report</div>
      <v-row justify="center" v-for="item in inventorytypes" v-bind:key="item.id">
        <div class="btn-report-container">
          <div class="active-report-icon">
            <v-icon large v-if="item.activeReport" class="reported-alert">mdi-alert-circle</v-icon>
          </div>
          <v-btn class="widebutton my-2" @click="goToReport(item.id)">
            <p class="btn-wrap-txt">{{ item.description }}</p>
          </v-btn>
        </div>
      </v-row>
    </div>

    <div class="text-center" v-if="isFormsActive.isContactRequestActive || isFormsActive.isFeedbackActive">
      <div class="infotext text-center mb-5 mt-10">Forms</div>
      <v-row justify="center" v-if="isFormsActive.isContactRequestActive">
        <v-btn class="widebutton my-2" @click="goToForm('contactrequest')">{{ contactRequest }}</v-btn>
      </v-row>
      <v-row justify="center" v-if="isFormsActive.isFeedbackActive">
        <v-btn class="widebutton my-2" @click="goToForm('feedback')">{{ feedback }}</v-btn>
      </v-row>
    </div>

    <div class="text-center">
      <p class="edit-qr" @click="goToEditMode">Edit</p>
    </div>

    <div v-if="felmeddelande">
      <div class="text-center mb-5">
        <i class="buttonicon far fa-thumbs-up mb-5"></i><br />
        Sorry, something went wrong. <br />
        You might have scanned a code that dont exists in our system<br />
        <br />
        <br />
        {{ felmeddelande }}
      </div>
    </div>
  </div>
</template>

<script>

import { HTTP } from '@/plugins/backendAPI'

export default {
  props: ['qrstring', 'workspaceid', 'qrworkspaceid'],
  data() {
    return {
      contactRequest: 'Contact request',
      feedback: 'Feedback',
      inventorytypes: [],
      isFormsActive: {},
      felmeddelande: null,

      qrName: null,
      workspaceId: null,
      qrWorkspaceId: null,
    }
  },
  created() {
    var self = this;
    HTTP.get(`/mobil/checkifqrexists/${self.qrstring}/${self.workspaceid}/${self.qrworkspaceid}`).then(response => {
      if (!response.data.isFound) {
        const wId = self.qrstring !== undefined ?  response.data.legacyWorkspaceId : parseInt(self.workspaceid);
        self.$router.push({ name: 'EditMobile', params: { qrstring: self.qrstring, workspaceid: wId, qrworkspaceid: self.qrworkspaceid, qrname: self.qrName } }).catch(() => { });
      }
      else {
        self.workspaceId = response.data.workspaceId;
        self.qrWorkspaceId = response.data.qrWorkspaceId;

        HTTP.get(`/mobil/getcontactforms/${self.workspaceId}/${self.qrWorkspaceId}`).then(response => {
          self.qrName = response.data.qrname
          self.inventorytypes = response.data.inventorytypes
          self.isFormsActive = response.data.form
        })
      }
    }).catch((err) => {
      this.felmeddelande = err.response.data
    })
  },
  methods: {
    goToEditMode() {
      this.$router.push({ name: 'EditMobile', params: { workspaceid: this.workspaceId, qrworkspaceid: this.qrWorkspaceId, isEditMode: true, qrname: this.qrName } }).catch(() => { });
    },
    goToReport(item) {
      this.$router.push({ name: 'MobileReport', params: { workspaceid: this.workspaceId, qrworkspaceid: this.qrWorkspaceId, qrdetailid: item } }).catch(() => { });
    },
    goToForm(formType){
      this.$router.push({ name: 'MobileForm', params: { formtype: formType, workspaceid: this.workspaceId, qrworkspaceid: this.qrWorkspaceId, qrname: this.qrName } }).catch(() => { });
    }
  },
}

</script>

<style scoped>
.edit-qr {
  display: inline-block;
  font-size: 18px;
  text-decoration: underline;
  margin-top: 120px;
  cursor: pointer;
  color: lightgray;
}

.edit-qr:hover {
  color: lightskyblue;
}

.btn-report-container {
  position: relative;
}

.btn-wrap-txt {
  white-space: normal;
  word-wrap: break-word;
  overflow: hidden;
  width: 180px;
  max-height: 60px;
  margin: 0;
}

.active-report-icon {
  position: absolute;
  z-index: 50;
  right: -8px;
  top: -5px;
  height: 35px;
}
</style>