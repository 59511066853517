<template>
  <div>
    <qrcode :value="qrcodeurl()" :options="{ width: 200 }"></qrcode>
  </div>
</template>

<script>

export default {
  props: ['qrstring', 'workspaceid', 'qrworkspaceid'],
  data() {
    return {
    }
  },
  methods: {
    qrcodeurl() {
      let urlstring = this.qrstring;
      if (this.qrstring === 'undefined' || this.qrstring === undefined)
        urlstring = `${this.workspaceid}/${this.qrworkspaceid}`;
      // eslint-disable-next-line
      return process.env.VUE_APP_CLIENTURL + '/m/' + urlstring

    }
  }
}
</script>
<style></style>