<!-- eslint-disable no-console -->
<template>
  <div class="adminbackground">
    <span v-if="isauthorized">
      <autoLogout></autoLogout>
    </span>
    <appbar></appbar>
    <v-row justify="center" class="mt-5">
      <v-col md="9" sm="12">
        <v-data-table dense :search="search" :headers="getQrHeaders" :items="qritems" :sort-by.sync="sortOptions.sortBy"
          :sort-desc.sync="sortOptions.sortDesc" :options.sync="sortHandler" :items-per-page="20"
          :footer-props="footerProps" :custom-filter="filter" ref="qr_data_table" @current-items="updateSearchedItems"
          class="elevation-1">
          <template v-slot:top>
            <div class="d-flex align-start mx-5 pa-3">
              <h2 class="mr-auto">QR Codes</h2>
              <ExportButton v-if="isadministrator || issuperadministrator" :table-items="searchedItems"
                :table-type="'qr_codes'" :export-message="'Export List'"></ExportButton>
              <Tooltip :message="tooltipMessage"></Tooltip>
            </div>
            <v-row class="d-flex align-center mx-5">
              <v-col cols=8>
                <v-text-field v-model="search" label="Search"></v-text-field>
              </v-col>
              <v-icon large v-if="search.length > 0" class="close-button" @click="search = ''">mdi-close</v-icon>
              <v-col cols=3>
                <v-btn :class="search.length > 0 && 'margin-left-10'" color="white" @click="newqr()"><v-icon small
                    class="py-2 mr-2">mdi-flip-to-front</v-icon> New</v-btn>
              </v-col>
            </v-row>
          </template>
          <template #item.url="{ value }">
            <a :href="value" target="_blank">{{ value }}</a>
          </template>
          <template #item.qrUrl="{ value }">
            <a :href="value" target="_blank"><v-icon small class="py-2 mr-1">mdi-open-in-new</v-icon></a>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon medium class="pa-2" @click="
              editItem(item);
            qrCategoryChanged = false;
            ">mdi-pencil</v-icon>
            <v-icon medium class="pa-2 fas fa-copy" @click="showcopydialog(item)"></v-icon>
            <v-icon medium class="pa-2" @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
        <v-dialog v-model="copydialog" max-width="500px" class="dialogbox">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card>
              <v-card-title>
                <span class="headline">Generate copy(s) of {{ qrname }}</span>
              </v-card-title>
              <v-col cols="3">
                <v-text-field ref="copyamount" v-model="copyamount" label="No. copies"></v-text-field>
              </v-col>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn tile outlined class="mb-3" @click="copydialog = false"><v-icon small
                    class="py-2 mr-1">mdi-close</v-icon>
                  Cancel</v-btn>
                <v-btn tile outlined class="mb-3 mr-3" :disabled="copyamount > 999" @click="generatecopies()"><v-icon
                    small class="py-2 mr-1">mdi-content-save</v-icon>
                  Create copies</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
        <v-dialog v-model="dialog" max-width="800px" class="dialogbox" ref="maindialog">
          <v-card>
            <v-card-title>
              <span class="headline">{{
                this.editedIndex === -1 ? "New" : "Edit"
                }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form ref="mainform" v-model="isFormValid">
                  <v-row>
                    <v-col cols="3" v-if="islegacy">
                      <v-text-field required ref="qrid" v-model="editedItem.qrString" :rules="qrRules"
                        label="QRID"></v-text-field>
                    </v-col>
                    <v-col cols="3" v-else>
                      <v-text-field required ref="qrid" v-model="editedItem.qrWorkspaceId" :rules="qrRules"
                        type="number" min="1" label="QRID"></v-text-field>
                    </v-col>
                    <v-col cols="9">
                      <v-text-field v-model="editedItem.description" label="Description"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" @click="qrCategoryChanged = true">
                      <v-combobox v-model="selectedQrCategory" :items="qrCategories" item-value="id"
                        item-text="description" required :rules="requiredRules" label="Category">
                        <template v-slot:item="{ item, on, attrs }">
                          <v-list-item v-on="on" v-bind="attrs" :class="item.description === 'Uncategorized' &&
                            'uncategorized-list-item'
                            ">
                            {{ item.description }}
                          </v-list-item>
                        </template>
                      </v-combobox>
                    </v-col>
                    <v-col cols="4">
                      <v-combobox v-model="selectedLocation" :items="locations" item-value="id" item-text="name"
                        label="Location" @change="clearCoordinates" ref="locationcombobox"></v-combobox>
                    </v-col>
                    <v-col cols="1">
                      <v-icon v-if="selectedLocation && selectedLocation.imageId" @click="imageDialog = true" large
                        class="map-marker"
                        :class="editedItem.latLng && 'map-marker-selected'">mdi-map-marker-radius</v-icon>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field v-model="editedItem.position" label="Location description"></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
                <v-col v-if="inventoryTypesText.length > 0" cols="7" class="qr-typeinfo">
                  <div class="typeinfo">{{ inventoryTypesText }}</div>
                </v-col>
                <v-spacer></v-spacer>
                <v-row>
                  <v-col cols="9">
                    <span class="headline">Inventory</span>
                  </v-col>
                  <v-col cols="3">
                    <v-btn tile outlined @click="newqrdetail()" :disabled="!isFormValid"><v-icon small
                        class="py-2 mr-2">mdi-flip-to-front</v-icon>New</v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-data-table dense :headers="qrdetailheaders" :items="qrDetailItemsWithKey" hide-default-footer
                      disable-pagination item-key="key" class="elevation-1">
                      <template v-slot:item.action="{ item }">
                        <v-icon medium class="pa-2" @click="editqrdetail(item)">mdi-pencil</v-icon>
                        <v-icon medium class="pa-2" @click="deleteqrdetail(item)">mdi-delete</v-icon>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="9">
                    <span class="headline">Forms</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-data-table dense :headers="formHeaders" :items="getForms" hide-default-footer disable-pagination
                      class="elevation-1">
                      <template v-slot:item.isActive="{ item }">
                        <v-col class="switch-col">
                          <v-switch @click="switchActive(item)" v-model="item.isActive" inset
                            class="switch-btn"></v-switch>
                        </v-col>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn tile outlined class="mb-3" @click="close"><v-icon small class="py-2 mr-1">mdi-close</v-icon>
                Cancel</v-btn>
              <v-btn tile outlined class="mb-3 mr-3" @click="save" :disabled="!isFormValid"><v-icon small
                  class="py-2 mr-1">mdi-content-save</v-icon>
                Save</v-btn>
            </v-card-actions>
          </v-card>
          <v-dialog v-model="imageDialog" max-width="80vw" eager>
            <v-card>
              <v-card-title>
                <span class="headline m-b-6">QR Location</span>
              </v-card-title>
              <v-container>
                <div class="map-area-general">
                  <MapComponent v-if="
                    selectedLocation != null &&
                    selectedLocation.imageName &&
                    imageDialog
                  " :qrId="editedItem.id" :name="selectedLocation.imageName" :latLng="editedItem.latLng"
                    @updateLocation="updateLocationFromChild" />
                </div>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn tile outlined class="mb-3" @click="clearCoordinates"><v-icon small
                    class="py-2 mr-1">mdi-close</v-icon>
                  Clear</v-btn>
                <v-btn tile outlined class="mb-3 mr-3" @click="imageDialog = false"><v-icon small
                    class="py-2 mr-1">mdi-close</v-icon>
                  Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="qrdetaildialog" max-width="600px" class="dialogbox" eager>
            <v-card>
              <v-card-title>
                <span class="headline">{{
                  this.editedqrdetail.id === 0
                    ? "New inventory"
                    : "Edit inventory"
                }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form v-model="isFormValidDetail" ref="qrdetailform">
                    <v-row>
                      <v-col cols="6">
                        <v-combobox v-model="editedqrdetailselected" :items="inventoryitems" item-value="id"
                          item-text="description" required :rules="requiredRules" label="Item">
                        </v-combobox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field disabled v-model="editedqrdetail.typeDescription"
                          label="Type description"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="editedqrdetail.aliasTypeDescription"
                          label="Custom type description"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn tile outlined class="mb-3" @click="qrdetaildialog = false"><v-icon small
                    class="py-2 mr-1">mdi-close</v-icon>
                  Cancel</v-btn>
                <v-btn tile outlined class="mb-3 mr-3" @click="saveqrdetails()" :disabled="!isFormValidDetail"><v-icon
                    small class="py-2 mr-1">mdi-content-save</v-icon>
                  Ok</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { HTTP } from "@/plugins/backendAPI";
import autoLogout from "@/components/AutoLogout";
import { mapGetters } from "vuex";
import MapComponent from "../../components/MapComponent.vue";
import { EventBus } from "@/store/event-bus.js";
import Tooltip from "../../components/Tooltip.vue";
import ExportButton from "../../components/ExportButton.vue";

const contactRequest = "Contact request";
const feedback = "Feedback";

export default {
  name: "home",
  props: {
    inventoryId: {
      type: String,
      default: "",
    },
  },
  components: {
    autoLogout,
    MapComponent,
    Tooltip,
    ExportButton,
  },
  data() {
    return {
      qrCategoryChanged: false,
      qrCategoryReverted: false,

      dialog: false,
      qrdetaildialog: false,
      imageDialog: false,

      enableEscClear: true,
      qrname: "",
      copydialog: false,
      search: this.inventoryId,
      defaultWorkspace: "",

      qritems: [],
      searchedItems: [],
      qrdetailheaders: [
        { text: "Name", value: "description" },
        { text: "Type description", value: "typeDescription" },
        { text: "Custom type description", value: "aliasTypeDescription" },
        { text: "Edit", value: "action", sortable: false, width: 130 },
      ],
      formHeaders: [
        { text: "Description", value: "description", sortable: false },
        { text: "Active", value: "isActive", sortable: false, width: 150 },
      ],
      editedItem: {},
      editedIndex: null,
      locations: [],
      selectedLocation: {},
      qrCategories: [],
      selectedQrCategory: null,
      copyamount: null,
      selectedqr: null,
      qrdetailitems: [],
      editedqrdetail: {},
      editedqrdetailselected: {},
      inventoryitems: [],
      inventoryTypesText: "",
      inventoryTypesByCategory: [],
      defaultForms: [],
      isContactRequestActive: null,
      isFeedbackActive: null,

      footerProps: { "items-per-page-options": [20, 50, 100] },
      modellitems: {},
      valid: true,
      isFormValid: false,
      isFormValidDetail: false,
      qrRules: [
        (v) => !!v || "QRID is required",
        (v) =>
          /^[a-öA-Ö0-9]+$/.test(v) ||
          "QRID can only contain letters and numbers",
      ],
      requiredRules: [(v) => !!v || "Field is required"],

      sortHandler: {},
      sortOptions: {
        sortBy: [],
        sortDesc: [],
      },

      tooltipMessage: [
        "QR Codes",
        'This section allows you to manage QR codes. Click "New" to add a new code. Edit existing codes by clicking the edit icon (pen), duplicate a code with the duplicate icon, or delete it with the trash icon. All settings of the listed QR codes are searchable, such as a device name or location.',
      ],
    };
  },
  computed: {
    ...mapGetters([
      "isauthorized",
      "isadministrator",
      "issuperadministrator",
      "sort",
      "workspaceid",
      "islegacy",
      "workspaceitems",
    ]),
    getForms() {
      return [
        {
          description: contactRequest,
          isActive: this.editedItem.isContactRequestActive ? 1 : 0,
        },
        {
          description: feedback,
          isActive: this.editedItem.isFeedbackActive ? 1 : 0,
        },
      ];
    },
    qrDetailItemsWithKey() {
      return this.qrdetailitems.map((item, index) => ({
        key: index,
        ...item,
      }));
    },
    getQrHeaders() {
      return [
        ...(this.islegacy
          ? [{ text: "QRID", align: "left", value: "qrString" }]
          : [{ text: "QRID", align: "left", value: "qrWorkspaceId" }]),
        { text: "Category", value: "qrCategoryDescription" },
        { text: "Description", value: "description", width: "25%" },
        { text: "Location", value: "location", width: "25%" },
        { text: "Url", value: "url", sortable: false },
        { text: "QR url", value: "qrUrl", width: 90, sortable: false },
        { text: "Edit", value: "action", sortable: false, width: 150 },
      ];
    },
  },
  created() {
    this.getqr(this.workspaceid);
    this.sortOptions = this.sort.qrSort || this.sortOptions;
    EventBus.$on("updateqritems", (workspaceid) => {
      this.qritems = [];
      this.getqr(workspaceid);
    });
  },
  mounted() {
    document.addEventListener("keydown", this.clearSearchEsc);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.clearSearchEsc);
  },
  watch: {
    dialog(val) {
      val || this.close();
      this.inventoryTypesText = "";

      if (val) this.enableEscClear = false;
      if (!val) {
        setTimeout(() => {
          this.enableEscClear = true;
        }, 100);
      }
    },
    editedqrdetailselected(val) {
      if (!val) return;

      var self = this;
      HTTP.get("/qr/getinventorytype/" + val.id).then((response) => {
        self.editedqrdetail.typeDescription = response.data;
        self.editedqrdetailselected.typeDescription = response.data;
      });
    },
    selectedQrCategory(val, oldVal) {
      if (!val) return;
      var self = this;
      self.editedItem.qrCategoryDescription = val.description;
      const isUncategorized = val.description.toString() === "Uncategorized";

      if (self.qrCategoryReverted) {
        self.qrCategoryReverted = false;
        return;
      }

      if (!self.qrCategoryChanged) {
        self.getInventoryTypes(val.id);
      } else {
        if (oldVal && self.qrdetailitems.length > 0 && !isUncategorized) {
          this.$root.$confirm
            .open(
              "Change QR Category",
              "Are you sure? All current items will be removed.",
              { color: "red" }
            )
            .then((response) => {
              if (response) {
                self.getInventoryTypes(val.id);
                self.qrdetailitems = [];
              } else {
                self.selectedQrCategory = oldVal;
                self.qrCategoryReverted = true;
              }
            });
        } else {
          self.getInventoryTypes(val.id);
        }
      }
    },
    qrdetaildialog(val) {
      if (!val) {
        setTimeout(() => {
          this.$refs.qrid.focus();
        }, 100);
      }
    },
    imageDialog(val) {
      if (!val) {
        setTimeout(() => {
          this.$refs.qrid.focus();
        }, 100);
      }
    },
    sortHandler: {
      deep: true,
      handler(obj) {
        const objSort = { sortBy: obj.sortBy, sortDesc: obj.sortDesc };

        if (JSON.stringify(objSort) !== JSON.stringify(this.sortOptions)) {
          const newObj = this.sort;
          newObj.qrSort = objSort;
          this.$store.dispatch("updateSort", newObj);
        }
      },
    },
  },
  methods: {
    updateSearchedItems() {
      this.$nextTick(() => {
        this.searchedItems = this.$refs.qr_data_table.$children[0].filteredItems
      });
    },
    getqr(workspaceid) {
      var self = this;
      HTTP.get("/qr/get/" + workspaceid).then((response) => {
        self.defaultWorkspace = response.data.defaultWorkspace;
        self.qritems = response.data.qrs;
      });
    },
    editItem(item) {
      var self = this;
      self.editedIndex = self.qritems.findIndex((q) => q.id === item.id);
      HTTP.get("/qr/edit/" + item.id).then((response) => {
        self.editedItem = response.data.qr;
        self.qrCategories = response.data.qrCategories;
        self.qrdetailitems = response.data.qrDetails;
        self.selectedQrCategory = self.qrCategories.find(
          (e) => e.id == self.editedItem.qrCategoryId
        );
        self.locations = response.data.locations;
        self.selectedLocation =
          self.locations.find((l) => l.id === self.editedItem.locationId) ||
          null;
        this.isContactRequestActive = response.data.qr.isContactRequestActive;
        this.isFeedbackActive = response.data.qr.isFeedbackActive;
      });
      this.dialog = true;
      setTimeout(() => {
        this.$refs.qrid.focus();
      }, 200);
    },
    editqrdetail(item) {
      var self = this;
      self.editedqrdetail = Object.assign(
        {},
        self.qrdetailitems.find((q) => q.id === item.id)
      );
      const isUncategorized =
        self.editedItem.qrCategoryDescription === "Uncategorized";
      HTTP.get(
        "/qr/getinventory/" +
        self.selectedQrCategory.id +
        "/" +
        isUncategorized +
        "/" +
        this.workspaceid
      ).then((response) => {
        self.inventoryitems = response.data;
        self.editedqrdetailselected = self.inventoryitems.find(
          (i) => i.id === self.editedqrdetail.inventoryId
        );
      });
      this.qrdetaildialog = true;
    },
    showcopydialog(item) {
      this.qrname = item.description;
      this.selectedqr = item;
      this.copydialog = true;
      setTimeout(() => {
        this.$refs.copyamount.focus();
      }, 200);
    },
    generatecopies() {
      this.copydialog = false;

      HTTP.post("/qr/copy", {
        id: this.selectedqr.id,
        copycount: this.copyamount,
      }).then(() => {
        this.getqr(this.workspaceid);
      });
    },
    getmodellitems() {
      HTTP.get("/inventory/getallmodeller").then((response) => {
        this.modellitems = response.data;
      });
    },
    getLocations() {
      HTTP.get("/location/get/" + this.workspaceid).then((response) => {
        this.locations = response.data;
      });
    },
    deleteItem(item) {
      const index = this.qritems.indexOf(item);
      this.$root.$confirm
        .open("Delete item", "Are you sure?", { color: "red" })
        .then((response) => {
          if (response) {
            HTTP.post("/qr/delete", { id: item.id })
              .then(() => {
                this.qritems.splice(index, 1);
              })
              .catch((e) => {
                this.$store.dispatch("showError", e.response.data);
              });
          }
        });
    },
    deleteqrdetail(item) {
      const index = this.qrDetailItemsWithKey.indexOf(item);
      this.qrdetailitems.splice(index, 1);
    },
    close() {
      this.dialog = false;
    },
    newqr() {
      var self = this;
      if (self.workspaceid == -1) {
        self.$store.dispatch(
          "showError",
          "No workspace selected.<br/><br/>Please make sure you have created at least one workspace and that it is selected."
        );
        return;
      }
      HTTP.get("/qr/new/" + this.workspaceid).then((response) => {
        self.selectedLocation = null;
        self.editedItem = response.data.qr;
        self.editedItem.workspaceid = this.workspaceid;
        self.isContactRequestActive = response.data.qr.isContactRequestActive;
        self.isFeedbackActive = response.data.qr.isFeedbackActive;
        self.editedIndex = -1;
        self.selectedQrCategory = null;
        self.qrCategories = response.data.qrCategories;
        self.qrdetailitems = response.data.qrDetails;
        self.dialog = true;
        self.getLocations();
        if (self.$refs.mainform) self.$refs.mainform.resetValidation();
        setTimeout(() => {
          self.$refs.qrid.focus();
        }, 200);
      });
    },
    newqrdetail() {
      var self = this;
      const isUncategorized =
        self.editedItem.qrCategoryDescription === "Uncategorized";
      HTTP.get("/qr/newqrdetail").then((response) => {
        self.editedqrdetail = response.data;
        self.editedqrdetailselected = null;
        self.getInventoryByType(self.selectedQrCategory.id, isUncategorized);
        self.qrdetaildialog = true;
        if (this.$refs.qrdetailform) this.$refs.qrdetailform.resetValidation();
      });
    },
    save() {
      var self = this;
      self.$refs.locationcombobox.blur();

      self.$nextTick(() => {
        let locationName = "";

        self.editedItem.qrCategoryId = self.selectedQrCategory.id;
        self.editedItem.isContactRequestActive = this.isContactRequestActive;
        self.editedItem.isFeedbackActive = this.isFeedbackActive;

        if (self.selectedLocation) {
          if (self.selectedLocation.id) {
            locationName = self.selectedLocation.name;
            self.editedItem.newLocation = null;
            self.editedItem.locationId = self.selectedLocation.id;
          } else {
            locationName = self.selectedLocation;
            self.editedItem.locationId = null;
            self.editedItem.newLocation = self.selectedLocation;
            self.editedItem.workspaceId = self.workspaceid;
          }
        } else {
          self.editedItem.locationId = null;
        }

        if (self.editedItem.position) {
          locationName !== ""
            ? (locationName += " - " + self.editedItem.position)
            : (locationName = self.editedItem.position);
        } else {
          self.editedItem.position = null;
        }

        HTTP.post("/qr/save", {
          qr: self.editedItem,
          qrDetails: self.qrdetailitems,
          qrCategories: null,
        })
          .then((response) => {
            const qrUrl = self.islegacy
              ? self.editedItem.qrString
              : `${self.editedItem.workspaceId}/${self.editedItem.qrWorkspaceId}`;

            if (self.editedIndex > -1) {
              var currentitem = self.qritems[self.editedIndex];
              currentitem.qrString = self.editedItem.qrString;
              currentitem.description = self.editedItem.description;
              currentitem.location = locationName;
              currentitem.qrCategoryDescription = self.qrCategories.find(
                (t) => t.id === self.editedItem.qrCategoryId
              ).description;
              // eslint-disable-next-line
              currentitem.url = process.env.VUE_APP_CLIENTURL + "/m/" + qrUrl;
              currentitem.qrUrl =
                // eslint-disable-next-line no-undef
                process.env.VUE_APP_CLIENTURL + "/qrimage/" + qrUrl;
            } else {
              self.editedItem.id = response.data;
              self.editedItem.qrCategoryDescription = self.qrCategories.find(
                (t) => t.id === self.editedItem.qrCategoryId
              ).description;
              self.editedItem.location = locationName;
              self.editedItem.url =
                // eslint-disable-next-line no-undef
                process.env.VUE_APP_CLIENTURL + "/m/" + qrUrl;
              self.editedItem.qrUrl =
                // eslint-disable-next-line no-undef
                process.env.VUE_APP_CLIENTURL + "/qrimage/" + qrUrl;
              self.qritems.push(self.editedItem);
            }
          })
          .catch((e) => {
            this.$store.dispatch("showError", e.response.data);
          })
          .then(() => {
            this.dialog = false;
          });
      });
    },
    saveqrdetails() {
      var currentIndex = this.qrdetailitems.findIndex(
        (q) => q.id == this.editedqrdetail.id
      );
      if (currentIndex === -1) {
        this.editedqrdetail.id = -1;
        this.editedqrdetail.inventoryId = this.editedqrdetailselected.id;
        this.editedqrdetail.description =
          this.editedqrdetailselected.description.split("(")[0];
        this.qrdetailitems.push(this.editedqrdetail);
      } else {
        this.qrdetailitems[currentIndex].inventoryId =
          this.editedqrdetailselected.id;
        this.qrdetailitems[currentIndex].description =
          this.editedqrdetailselected.description;
        this.qrdetailitems[currentIndex].typeDescription =
          this.editedqrdetailselected.typeDescription;
        this.qrdetailitems[currentIndex].aliasTypeDescription =
          this.editedqrdetail.aliasTypeDescription;
      }
      this.qrdetaildialog = false;
    },
    getInventoryByType(qrCategoryId, isUncategorized) {
      var self = this;
      HTTP.get(
        "/qr/getinventory/" +
        qrCategoryId +
        "/" +
        isUncategorized +
        "/" +
        self.workspaceid
      ).then((response) => {
        self.inventoryitems = response.data;
      });
    },
    getInventoryTypes(id) {
      this.inventoryTypesText = "";
      this.inventoryTypesByCategory = [];

      HTTP.get("/qrcategory/getinventorytypes/" + id).then((response) => {
        for (var i = 0; i < response.data.length; i++) {
          if (response.data[i].qrCategory === id) {
            this.inventoryTypesByCategory.push(response.data[i].inventoryType);
          }
        }
        for (var j = 0; j < this.inventoryTypesByCategory.length; j++) {
          if (j <= this.inventoryTypesByCategory.length - 2) {
            this.inventoryTypesText += this.inventoryTypesByCategory[j] + ", ";
          } else {
            this.inventoryTypesText += this.inventoryTypesByCategory[j];
          }
        }
      });
    },
    switchActive(item) {
      const typeOfSwitch = item.description;
      if (typeOfSwitch === contactRequest)
        this.isContactRequestActive = item.isActive;
      if (typeOfSwitch === feedback) this.isFeedbackActive = item.isActive;
    },
    filter(value, search, item) {
      return Object.values(item).some((prop) => {
        return String(prop).toLowerCase().includes(search.toLowerCase());
      });
    },
    clearSearchEsc(e) {
      if (e.keyCode === 27 && this.enableEscClear) {
        this.search = "";
      }
    },
    updateLocationFromChild(value) {
      this.editedItem.latLng = value;
    },
    clearCoordinates() {
      this.editedItem.latLng = null;
    },
  },
};
</script>
