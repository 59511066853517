import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import mobileappbar from '@/components/mobileappbar'
import appbar from '@/components/appbar'
import VueQrcode from '@chenfengyuan/vue-qrcode';
import vuemoment from 'vue-moment'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import 'vuetify/dist/vuetify.min.css'

const vuetify = new Vuetify()

Vue.use(vuemoment)
Vue.use(Vuetify);
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi'
})
Vue.component('mobileappbar', mobileappbar)
Vue.component('appbar', appbar)
Vue.component(VueQrcode.name, VueQrcode);

Vue.filter('dateonly', function (value) {
  if (value) {
    return Vue.moment(value).format('YYYY-MM-DD')
  }
  return null
})

Vue.filter('longdate', function (value) {
  return Vue.moment(value).format('YYYY-MM-DD HH:mm')
})

export default new Vuetify({});
