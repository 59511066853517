<template>
  <div class="adminbackground">
    <appbar></appbar>
    <v-row justify="center" class="mt-5">
      <v-col md="9" sm="12">
        <v-data-table dense :headers="headers" :items="tableItems" hide-default-footer disable-pagination
          class="elevation-1">
          <template v-slot:top>
            <div class="d-flex align-start mx-5 pa-3">
              <h2 class="mr-auto">Organizations</h2>
              <v-btn v-if="issuperadministrator" @click="sendInvoiceBasisEmail()" color="white">
                <v-icon>mdi-email</v-icon>
                <span class="ml-2">Send Invoice Data</span>
              </v-btn>
            </div>
            <v-row class="d-flex align-center mx-5">
              <v-col cols=8>
                <v-text-field v-model="search" label="Search"></v-text-field>
              </v-col>
              <v-icon large v-if="search.length > 0" class="close-button" @click="search = ''">mdi-close</v-icon>
              <v-col cols=3>
                <v-btn :class="search.length > 0 && 'margin-left-10'" color="white" @click="newitem()"><v-icon small
                    class="py-2 mr-2">mdi-flip-to-front</v-icon> New</v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.isSuperAdminOrganization="{ item }">
            <v-simple-checkbox v-model="item.isSuperAdminOrganization" disabled></v-simple-checkbox>
          </template>
          <template v-slot:item.sendInvoiceData="{ item }">
            <v-simple-checkbox @click="saveSendInvoiceDataUpdate(item)"
              v-model="item.sendInvoiceData"></v-simple-checkbox>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon medium class="pa-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon v-if="!item.isSuperAdminOrganization" medium class="pa-2"
              @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
        <v-dialog v-model="dialog" max-width="800px" class="dialogbox" eager>
          <v-card>
            <v-card-title>
              <span class="headline">{{ this.editedItem.id === 0 ? 'New' : 'Edit' }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form v-model="isFormValid" ref="organizationform">
                  <v-row>
                    <v-col>
                      <v-text-field required :rules="reqRules" v-model="editedItem.name" label="Name"></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn tile outlined class="mb-3" @click="dialog = false"><v-icon small
                  class="py-2 mr-1">mdi-close</v-icon>
                Cancel</v-btn>
              <v-btn tile outlined class="mb-3 mr-3" @click="save()" :disabled="!isFormValid"><v-icon small
                  class="py-2 mr-1">mdi-content-save</v-icon> Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { HTTP } from '@/plugins/backendAPI'
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      dialog: false,
      search: '',
      footerProps: { 'items-per-page-options': [50, 100] },
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Super admin org.', value: 'isSuperAdminOrganization' },
        { text: 'Send invoice data', value: 'sendInvoiceData' },
        { text: 'Edit', value: 'action', sortable: false, width: 130 }
      ],
      tableItems: [],
      editedItem: {},
      newItem: false,
      reqRules: [
        v => !!v || 'Field is required',
      ],
      isFormValid: false,
    }
  },
  created() {
    this.getitems()
  },
  computed: {
    ...mapGetters([
      "issuperadministrator",
    ]),
  },
  methods: {
    saveSendInvoiceDataUpdate(item) {
      HTTP.post("/organization/save/sendinvoicedata", item).catch(e => {
        this.$store.dispatch('showError', e.response.data)
      })
    },
    sendInvoiceBasisEmail() {
      this.$root.$confirm
        .open("Send Invoice Data Email", "Are you sure?", { color: "red" })
        .then((response) => {
          if (response) {
            HTTP.post('/organization/send_invoice_data').catch(e => {
              this.$store.dispatch('showError', e.response.data)
              this.$router.push('/issues')
            })
          }
        });
    },
    getitems() {
      HTTP.get('/organization/get').then(response => {
        this.tableItems = response.data
      }).catch(e => {
        this.$store.dispatch('showError', e.response.data)
        this.$router.push('/issues')
      })
    },
    newitem() {
      this.newItem = true
      this.editedItem = { id: 0, name: null, isSuperAdminOrganization: false }
      this.editItem(this.editedItem)
    },
    editItem(item) {
      var self = this
      self.editedItem = Object.assign({}, item)
      self.dialog = true
      setTimeout(() => {
        if (this.$refs.organizationform) this.$refs.organizationform.resetValidation()
      }, 50)
    },
    save() {
      var self = this;
      HTTP.post('/organization/save', self.editedItem).then(response => {

        if (self.editedItem.id > 0) {
          var editedIndex = self.tableItems.findIndex(q => q.id === self.editedItem.id);
          self.tableItems[editedIndex].name = self.editedItem.name;
          self.tableItems[editedIndex].isSuperAdminOrganization = self.editedItem.isSuperAdminOrganization;
          self.tableItems[editedIndex].sendInvoiceData = self.editedItem.sendInvoiceData;
        } else {
          self.editedItem.id = response.data
          self.tableItems.push(self.editedItem)
          self.$store.dispatch('addOrganizationitem', self.editedItem)
        }
        self.dialog = false
      }).catch(e => {
        self.$store.dispatch('showError', 'Error when saving: ' + e.response.data)
      })
    },
    deleteItem(item) {
      const self = this
      this.$root.$confirm.open('Delete item', 'All related QR data including users will be deleted!<br/><br/>Are you sure you want to continue?.', { color: 'red' }).then((response) => {
        if (response) {
          HTTP.post('/organization/delete', item).then((response) => {
            self.tableItems.splice(self.tableItems.indexOf(item), 1);
            self.$store.dispatch('saveOrganizationId', -1);
            self.$store.dispatch('deleteOrganizationitem', item)

            response.data.forEach(workspace => {
              self.$store.dispatch('deleteWorkspaceitem', workspace)

              if (self.workspaceid == workspace.id) {
                self.$store.dispatch('saveWorkspaceId', -1);
              }
            });
          }).catch(e => {
            self.$store.dispatch('showError', e.response.data)
          })
        }
      })
    },
    getUserFullName(item) {
      return `${item.firstname} ${item.lastname}`;
    },
  }
}
</script>
