<template>
  <div class="adminbackground">
    <appbar></appbar>
    <v-row justify="center" class="mt-5">
      <v-col md="9" sm="12">
        <v-data-table dense :search="search" :headers="headers" :items="tableItems" :sort-by.sync="sortOptions.sortBy"
          :sort-desc.sync="sortOptions.sortDesc" :options.sync="sortHandler" :items-per-page="20"
          :footer-props="footerProps" class="elevation-1">
          <template v-slot:top>
            <div class="d-flex align-start mx-5 pa-3">
              <h2 class="mr-auto">Locations</h2>
              <Tooltip :message="tooltipMessage"></Tooltip>
            </div>
            <v-row class="d-flex align-center mx-5">
              <v-col cols=8>
                <v-text-field v-model="search" label="Search"></v-text-field>
              </v-col>
              <v-icon large v-if="search.length > 0" class="close-button" @click="search = ''">mdi-close</v-icon>
              <v-col cols=4>
                <v-btn class="me-3" :class="search.length > 0 && 'margin-left-10'" color="white" @click="newitem()">
                  <v-icon small class="py-2 mr-2">mdi-flip-to-front</v-icon> New
                </v-btn>
                <v-btn color="white" @click="editImages()">
                  <v-icon small class="py-2 mr-2">mdi-flip-to-front</v-icon> Edit images
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.registeredOnQrs="{ item }">
            {{ item.registeredOnQrs > 0 ? item.registeredOnQrs : '' }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon medium class="pa-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon medium class="pa-2" @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
        <v-dialog v-model="mapDialog" width="80vw" class="dialogbox" eager>
          <v-card>
            <v-card-title>
              {{ this.editedItem.id === 0 ? 'New' : 'Edit' }} Location
            </v-card-title>
            <v-container class="pt-0">
              <v-card-text class="py-0">
                <v-form v-model="isFormValid" ref="form">
                  <v-row>
                    <v-col cols="5" class="py-0">
                      <v-text-field class="mt-0" ref="name" required :rules="reqRules" v-model="editedItem.name"
                        label="Name"></v-text-field>
                    </v-col>
                    <v-col cols="5" class="py-0">
                      <v-combobox class="mt-0" item-value="id" item-text="imageOriginalName" v-model="imageItemSelected"
                        :items="imageItems" label="Image"></v-combobox>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <div class="grid-mapView">
                <div v-if="registeredQrs.imageName" class="map-area-general"
                  style="padding-right:12px!important;padding-left:0px!important">
                  <MapComponent v-if="displayMap" :qrId="-2" :name="registeredQrs.imageName" :qrs="registeredQrs.qrs"
                    :clearAllMarkers="clearAllMarkers" :showToolTipOnExternalHover="showToolTipOnExternalHover"
                    @updateLocation="updateLocationFromChild" />
                </div>
                <div v-else class="map-area-general" style="padding-right:12px!important;padding-left:0px!important">
                  <v-card class="no-image">
                    <v-icon size="70">mdi-image-off-outline</v-icon>
                    No image selected
                  </v-card>
                </div>
                <v-card>
                  <v-list flat dense>
                    <v-subheader class="text-subtitle-1 font-weight-bold">QRID's on location:</v-subheader>
                    <v-list-item-group>
                      <v-list-item v-for="(qr, index) in registeredQrs.qrs" :key="index" class="qr-list-item">
                        <v-list-item-content style="padding:0;" class="d-flex text-subtitle-1"
                          @click="addQrBackToMap(qr)" @mouseover="showMarkerToolTip(qr)"
                          @mouseout="showMarkerToolTip('')">
                          <div>
                            <v-icon v-if="!qr.latLng" class="add-marker-icon">mdi-map-marker-left</v-icon>
                            <span :class="(!!qr.latLng ? 'qr-has-pos' : 'qr-no-pos')">{{ qr.qrString }}</span>
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </div>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn tile outlined class="mb-3" @click="clearAllQrCoords"><v-icon small
                  class="py-2 mr-1">mdi-close</v-icon>
                Clear</v-btn>
              <v-btn tile outlined class="mb-3" @click="mapDialog = false"><v-icon small
                  class="py-2 mr-1">mdi-close</v-icon>
                Cancel</v-btn>
              <v-btn tile outlined class="mb-3 mr-3" :disabled="!isFormValid" @click="save()"><v-icon small
                  class="py-2 mr-1">mdi-content-save</v-icon> Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="imageDialog" max-width="800px" class="dialogbox" eager>
          <v-card>
            <v-card-title>
              <div class="image-dialog">
                Edit images
                <v-btn tile outlined @click="newImage()"><v-icon small
                    class="py-2 mr-2">mdi-flip-to-front</v-icon>Upload</v-btn>
              </div>
            </v-card-title>
            <v-container>
              <v-data-table dense :headers="imageHeaders" :items="imageItems" hide-default-footer disable-pagination
                item-key="key" class="elevation-1">
                <template v-slot:item.dateAdded="{ item }">
                  {{ item.dateAdded.split('T')[0] }} {{ item.dateAdded.split('T')[1].substring(0, 5) }}
                </template>
                <template v-slot:item.action="{ item }">
                  <v-icon medium class="pa-2" @click="editImageItem(item)">mdi-pencil</v-icon>
                  <v-icon medium class="pa-2" @click="deleteImageItem(item)">mdi-delete</v-icon>
                </template>
              </v-data-table>
              <v-text-field class="fake-text-field" ref="targetfakefield"></v-text-field>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn tile outlined class="mr-3" @click="imageDialog = false"><v-icon small
                    class="py-2 mr-1">mdi-close</v-icon> Close</v-btn>
              </v-card-actions>
            </v-container>
          </v-card>
          <v-dialog v-model="editImageDialog" max-width="600px" class="dialogbox" eager>
            <v-card>
              <v-card-title>
                <span class="headline">Update image</span>
              </v-card-title>
              <v-card-text style="padding-bottom:0;">
                <v-container style="padding-bottom:0;">
                  <v-form v-model="isEditImageFormValid" ref="editImageform">
                    <v-row>
                      <v-col cols="6" style="padding-bottom:0;">
                        <v-text-field disabled v-model="editedImage.currentImageName"
                          label="Current image"></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-btn tile outlined class="mt-3" @click="uploadReplacementImage"><v-icon small
                            class="py-2 mr-1">mdi-flip-to-front</v-icon> Upload new image</v-btn>
                        <input id="single-file-input" type="file" name="photos" accept="image/*"
                          @change="singleFileChange($event)" style="display:none;">
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" style="padding-bottom:0; padding-top:0;">
                        <v-text-field v-if="editedImage.replacementImageName" disabled
                          v-model="editedImage.replacementImageName" label="New image"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn tile outlined class="mb-3" @click="editImageDialog = false"><v-icon small
                    class="py-2 mr-1">mdi-close</v-icon> Cancel</v-btn>
                <v-btn tile outlined class="mb-3 mr-3" @click="saveEditedImage"
                  :disabled="!editedImage.replacementImage"><v-icon small class="py-2 mr-1">mdi-content-save</v-icon>
                  Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="newImageDialog" max-width="600px" class="dialogbox" eager>
            <v-card>
              <v-card-title>
                Select image(s)
              </v-card-title>
              <v-container>
                <form enctype="multipart/form-data" novalidate>
                  <div class="dropbox">
                    <input id="file-input" type="file" multiple name="photos" accept="image/*" class="input-file"
                      @change="filesChange($event); fileCount = $event.target.files.length">
                    <p v-if="!isUploadingFiles" class="upload-text">Drag your file(s) here</p>
                    <p v-if="!isUploadingFiles" class="upload-text">or click to browse.</p>
                    <p v-if="!isUploadingFiles" class="upload-text file-count">Files selected: {{ fileCount }}</p>
                    <p v-if="isUploadingFiles" class="upload-text">Uploading images...</p>
                  </div>
                </form>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn tile outlined class="mb-3" @click="newImageDialog = false; clearFileInput()"><v-icon small
                    class="py-2 mr-1">mdi-close</v-icon> Cancel</v-btn>
                <v-btn tile outlined class="mb-3 mr-3" :disabled="!isImageFormValid" @click="saveImage()"><v-icon small
                    class="py-2 mr-1">mdi-content-save</v-icon> Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import { HTTP } from '@/plugins/backendAPI'
import { mapGetters } from 'vuex'
import MapComponent from '../../components/MapComponent.vue'
import { EventBus } from '@/store/event-bus.js'
import Tooltip from '../../components/Tooltip.vue'

export default {
  components: {
    MapComponent,
    Tooltip
  },
  data() {
    return {
      imageDialog: false,
      newImageDialog: false,
      editImageDialog: false,
      mapDialog: false,
      enableEscClear: true,
      search: '',
      footerProps: { 'items-per-page-options': [20, 50, 100] },
      headers: [
        { text: 'Name', value: 'name', width: '30%' },
        { text: 'Image', value: 'imageOriginalName', width: '30%' },
        { text: 'Registered on QR', value: 'registeredOnQrs' },
        { text: 'Edit', value: 'action', sortable: false, width: 130 },

      ],
      imageHeaders: [
        { text: 'Added', value: 'dateAdded', width: 160 },
        { text: 'Name', value: 'imageOriginalName' },
        { text: 'Edit', value: 'action', sortable: false, width: 130 },
      ],

      tableItems: [],
      imageItems: [],

      editedItem: {},
      imageItemSelected: null,

      editedImage: {
        currentImageId: null,
        currentImageName: null,
        replacementImage: null,
        replacementImageName: null,
      },

      localPictures: [],

      reqRules: [
        v => !!v || 'Field is required',
      ],
      isFormValid: false,
      isImageFormValid: false,
      isEditImageFormValid: false,
      isUploadingFiles: false,

      fileCount: 0,

      sortHandler: {},
      sortOptions: {
        sortBy: [],
        sortDesc: [],
      },

      registeredQrs: {
        locationId: null,
        locationName: null,
        imageId: null,
        imageName: null,
        qrs: []
      },
      displayMap: false,
      clearAllMarkers: false,
      showToolTipOnExternalHover: '',

      tooltipMessage: [
        "Location",
        'Locations makes it possible to connect a map (image) to your qr-code, on the map you place a marker for exact positioning. To add a new location you need to upload an image first under “Edit images”. After you have done that you can choose “New”, name the location and select your image.'
      ],
    }
  },
  computed: {
    ...mapGetters([
      'sort',
      'workspaceid'
    ]),
  },
  watch: {
    sortHandler: {
      deep: true,
      handler(obj) {
        const objSort = { sortBy: obj.sortBy, sortDesc: obj.sortDesc }

        if (JSON.stringify(objSort) !== JSON.stringify(this.sortOptions)) {
          const newObj = this.sort
          newObj.locationSort = objSort
          this.$store.dispatch('updateSort', newObj)
        }
      }
    },
    imageDialog(val) {
      if (val) this.enableEscClear = false
      if (!val) {
        setTimeout(() => {
          this.enableEscClear = true
        }, 100)
      }
    },
    editImageDialog(val) {
      if (!val) {
        this.clearSingleFileInput()
        setTimeout(() => {
          this.$refs.targetfakefield.focus()
        }, 100)
      }
    },
    newImageDialog(val) {
      if (!val) {
        setTimeout(() => {
          this.$refs.targetfakefield.focus()
        }, 100)
      }
    },
    mapDialog(val) {
      if (val) {
        this.enableEscClear = false
        setTimeout(() => {
          this.displayMap = true
        }, 100)
      } else {
        this.displayMap = false
        setTimeout(() => {
          this.enableEscClear = true
        }, 100)
      }
    },
    imageItemSelected(val) {
      if (!val) {
        this.registeredQrs.imageName = null
      }
      else {
        this.registeredQrs.imageName = val.imageName
      }
    },
  },
  created() {
    this.getitems(this.workspaceid)
    this.sortOptions = this.sort.locationSort || this.sortOptions
    EventBus.$on('updatelocation', workspaceid => {
      this.getitems(workspaceid);
    });

  },
  mounted() {
    document.addEventListener('keydown', this.clearSearchEsc)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.clearSearchEsc)
  },
  methods: {
    getitems(workspaceid) {
      HTTP.get('/location/get/' + workspaceid).then(response => {
        this.tableItems = response.data
      })
    },
    updateLocationFromChild(latLng, qrId) {
      const index = this.registeredQrs.qrs.findIndex(x => x.qrId === qrId)
      this.registeredQrs.qrs[index].latLng = latLng
    },
    addQrBackToMap(qr) {
      if (qr.latLng !== null) return
      if (!this.registeredQrs.imageName) return

      const qrId = qr.qrId
      const index = this.registeredQrs.qrs.findIndex(x => x.qrId === qrId)
      this.registeredQrs.qrs[index].latLng = '0,0'
    },
    clearAllQrCoords() {
      this.clearAllMarkers = !this.clearAllMarkers
      this.registeredQrs.qrs.forEach(qr => {
        qr.latLng = null
      })
    },
    showMarkerToolTip(qr) {
      if (qr.latLng === null) return
      if (qr === '' && this.showToolTipOnExternalHover === '' || qr === '' && this.showToolTipOnExternalHover === undefined) return
      this.showToolTipOnExternalHover = qr.qrString
    },
    save() {
      this.saveLocationData()
      this.saveLatLngData()
      this.mapDialog = false
    },
    saveLocationData() {
      if (this.imageItemSelected) {
        this.editedItem.imageId = this.imageItemSelected.id;
        this.editedItem.imageOriginalName = this.imageItemSelected.imageOriginalName;
        this.editedItem.imageName = this.imageItemSelected.imageName;
      } else {
        this.editedItem.imageId = null;
        this.editedItem.imageOriginalName = null;
        this.editedItem.imageName = null;
      }
      HTTP.post('/location/save', { id: this.editedItem.id, imageId: this.editedItem.imageId, name: this.editedItem.name, workspaceid: this.workspaceid }).then(response => {
        if (this.editedItem.id > 0) {
          var editedIndex = this.tableItems.findIndex(x => x.id === this.editedItem.id);
          var editedLocation = this.tableItems[editedIndex];

          editedLocation.name = this.editedItem.name;
          editedLocation.id = this.editedItem.id;
          editedLocation.imageId = this.editedItem.imageId;
          editedLocation.imageOriginalName = this.editedItem.imageOriginalName;
          editedLocation.imageName = this.editedItem.imageName;
        }
        else {
          this.editedItem.id = response.data;
          this.tableItems.push(this.editedItem);
        }
      }).catch(e => {
        this.$store.dispatch('showError', 'Error when saving: ' + e.response.data);
      })
    },
    saveLatLngData() {
      if (this.registeredQrs.qrs.length > 0) {
        HTTP.post('/qr/saveqrlatlng', this.registeredQrs.qrs).then(() => { }).catch(e => {
          this.$store.dispatch('showError', 'Error when saving: ' + e.response.data)
        })
      }
    },
    newitem() {
      if (this.workspaceid == -1) {
        this.$store.dispatch('showError', 'No workspace selected.<br/><br/>Please make sure you have created at least one workspace and that it is selected.')
        return
      }
      const newLocation = {
        id: 0,
        name: '',
        imageId: null,
        imageOriginalName: null,
        registeredOnQrs: 0
      }
      this.editItem(newLocation)
    },
    newImage() {
      this.newImageDialog = true
    },
    editItem(item) {
      HTTP.get('/qr/getqrsbylocation/' + item.id).then(response => {
        this.registeredQrs.qrs = response.data
        this.registeredQrs.locationId = item.id
        this.registeredQrs.locationName = item.name
        this.registeredQrs.imageId = item.imageId
        this.registeredQrs.imageName = item.imageName

        this.mapDialog = true
      })

      this.editedItem = { ...item }
      if (this.$refs.form) this.$refs.form.resetValidation()
      HTTP.get('/image/get/' + this.workspaceid).then(response => {
        this.imageItems = response.data
        this.imageItemSelected = this.imageItems.find(x => x.id === item.imageId) || null
      })
      setTimeout(() => {
        this.$refs.name.focus()
      }, 200)
    },
    editImageItem(item) {
      this.editImageDialog = true

      this.editedImage.currentImageName = item.imageOriginalName
      this.editedImage.currentImageId = item.id
    },
    uploadReplacementImage() {
      document.getElementById('single-file-input').click();
    },
    deleteItem(item) {
      const index = this.tableItems.indexOf(item)

      this.tableItems
      this.$root.$confirm.open('Delete item', 'Are you sure?', { color: 'red' }).then((response) => {
        if (response) {
          HTTP.post('/location/delete', { id: item.id }).then(() => {
            this.tableItems.splice(index, 1)
          }).catch(e => {
            this.$store.dispatch('showError', e.response.data)
          })
        }
      })
    },
    deleteImageItem(item) {
      const index = this.imageItems.indexOf(item)

      this.$root.$confirm.open('Delete item', 'Are you sure?', { color: 'red' }).then((response) => {
        if (response) {
          HTTP.post('/image/delete', { id: item.id }).then(() => {
            this.imageItems.splice(index, 1)
          }).catch(e => {
            this.$store.dispatch('showError', e.response.data)
          })
        }
      })
    },
    editImages() {
      if (this.workspaceid == -1) {
        this.$store.dispatch('showError', 'No workspace selected.<br/><br/>Please make sure you have created at least one workspace and that it is selected.')
        return
      }
      this.imageDialog = true
      HTTP.get('/image/get/' + this.workspaceid).then(response => {
        this.imageItems = response.data
      })
    },
    saveImage() {
      this.isUploadingFiles = true

      HTTP.post('/image/save', this.localPictures).then(response => {
        this.isUploadingFiles = false
        this.newImageDialog = false
        this.clearFileInput()

        response.data.forEach(img => {
          this.imageItems.push({ id: img.id, imageOriginalName: img.imageOriginalName, dateAdded: img.dateAdded })
        })
      }).catch((e) => {
        this.isUploadingFiles = false
        this.clearFileInput()
        this.$store.dispatch('showError', 'Error when saving: ' + e.response.data)
      })
    },
    saveEditedImage() {
      HTTP.post('/image/updateimage', this.editedImage).then(response => {
        this.editImageDialog = false
        this.clearSingleFileInput()

        const index = this.imageItems.findIndex(x => x.id === response.data.id)
        this.imageItems[index].imageOriginalName = response.data.imageOriginalName

        this.tableItems.forEach(item => {
          if (item.imageId === response.data.id) {
            item.imageOriginalName = response.data.imageOriginalName
          }
        })
      }).catch((e) => {
        this.clearSingleFileInput()
        this.$store.dispatch('showError', 'Error when saving: ' + e.response.data)
      })
    },
    filesChange(e) {
      if (!e.target.files.length) {
        this.isImageFormValid = false
        return;
      }

      this.isImageFormValid = true
      const files = e.target.files;
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const newPic = {
            filedata: reader.result.replace('data:', '').replace(/^.+,/, ''),
            filename: file.name,
            filesize: file.size,
            filetype: file.type,
            workspaceid: this.workspaceid
          }
          this.localPictures.push(newPic)
        }
        reader.readAsDataURL(file);
      })
    },
    singleFileChange(e) {
      if (!e.target.files.length) {
        return;
      }

      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const newPic = {
          filedata: reader.result.replace('data:', '').replace(/^.+,/, ''),
          filename: file.name,
          filesize: file.size,
          filetype: file.type,
        }
        this.editedImage.replacementImage = newPic
        this.editedImage.replacementImageName = newPic.filename
      }
      reader.readAsDataURL(file);
    },
    clearFileInput() {
      document.getElementById('file-input').value = null
      this.fileCount = 0
      this.localPictures = []
      this.isImageFormValid = false
    },
    clearSingleFileInput() {
      this.editedImage = {
        currentImageId: null,
        currentImageName: null,
        replacementImage: null,
        replacementImageName: null,
      }

      document.getElementById('single-file-input').value = null
    },
    clearSearchEsc(e) {
      if (e.keyCode === 27) {
        if (this.mapDialog) this.mapDialog = false
        if (this.enableEscClear) this.search = ''
      }
    },
  }
}
</script>
<style scoped>
.image-dialog {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.dropbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;

  background-color: #EBF5FB;
  width: 100%;
  height: 100px;
  border-radius: 15px;
  border: 2px solid lightgrey;
  position: relative;
}

.input-file {
  width: 100%;
  height: 100px;
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.upload-text {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin: 0;
  padding: 0;
}

.file-count {
  font-weight: bold;
  font-style: italic;
}

::file-selector-button {
  display: none;
}

.grid-mapView {
  display: grid;
  grid-template-columns: auto 180px;
  padding-left: 10px;
  padding-right: 10px;
}

.qr-list-item {
  padding-left: 10px;
}

.qr-has-pos {
  color: lightgray;
}

.qr-no-pos:hover {
  text-decoration: underline;
}

.add-marker-icon {
  width: 20px;
  max-width: 20px;
  margin-bottom: 4px;
  margin-right: 3px;
  padding-left: 2px;
  color: var(--report-red) !important;
}
</style>
