<template>
    <v-main>
      <v-container>
        <v-layout align-center justify-center mt-5>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <!-- <v-toolbar color="primary" flat>
                <v-toolbar-title>Change password</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar> -->
              <v-card-text class="">
                <v-form v-model="valid" ref="form">
                  <div class="py-8 text-center"><img src="./../assets/service-me-logo.png" class="biglogo"/></div>
                  <v-text-field :disabled="passwordsent" required v-model="password1" id="password" name="password" label="Password" type="password" ></v-text-field>
                  <v-text-field :disabled="passwordsent" required v-model="password2" id="password" name="password" label="Enter password again" type="password"></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn v-if="passwordsent" color="primary" href="/login">To login page</v-btn>
                <v-spacer></v-spacer>
                <v-btn :disabled="passwordsent" color="primary" @click="changepassword()">Save new password</v-btn>
              </v-card-actions>
              <v-alert v-if="info" type="success">{{info}}</v-alert>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>

    <!-- <v-container>
  <v-form v-model="valid" ref="form">
    <v-flex xs6 offset-xs3>
      <v-flex xs6 offset-xs3 mb-3>
        <label class="headline">Change password</label>
      </v-flex>
      <v-layout row>
        <v-text-field :disabled="passwordsent" required v-model="password1" id="password" name="password" label="Password" type="password" ></v-text-field>
      </v-layout>
      <v-layout row>
        <v-text-field :disabled="passwordsent" required v-model="password2" id="password" name="password" label="Enter password again" type="password"></v-text-field>
      </v-layout>
      <v-layout row>
        <v-flex xs6 offset-xs6>
          <v-btn :disabled="passwordsent" color="primary" block @click="changepassword()">Save new password</v-btn>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-form>
    <div class="mt-5">
    <v-alert type="error" :value="showsnackbarerror">
      {{ snackbartexterror }}
    </v-alert>
    <v-alert type="success" :value="showsnackbar">
      {{ snackbartext }}
      <v-btn  :to="`/login`">Login</v-btn>
    </v-alert>    
    </div>
</v-container> -->
</template>





<script>
import { HTTP } from '@/plugins/backendAPI'

export default {
  props: ['guid'],
  data () {
    return {
      valid: true,
      loading: false,
      password1: null,
      password2: null,
      info: null,
      passwordsent: false
    }
  },
  created () {
    HTTP.get('/account/validateguid/' + this.guid).catch(e => {
        this.snackbartexterror = e.response.data
        this.showsnackbarerror = true
      })
  },
  methods: {
    changepassword () {
      if (this.$refs.form.validate() && this.password1 !== null && this.password1 === this.password2) {
        this.loading = true
        HTTP.post('/account/changepassword', {password: this.password1, guid: this.guid}).then(() => {
            this.info = 'Password is changed. Login with the new password.'
            this.showsnackbar = true
            this.passwordsent = true
            
          }).catch(e => {
            this.$store.dispatch('showError', e.response.data)
          }).then(() => {
this.loading = false
          })
      } else {
        this.$store.dispatch('showError', 'Enter the same password in both fields')
      }
    }
  }
}

</script>

<style lang="css" scoped>

</style>
