<template>
  <div class="adminbackground">
    <appbar></appbar>
    <v-row justify="center" class="mt-5">
      <v-col md="9" sm="12">
        <v-data-table :search="search" :headers="headers" :items="tableItems" :sort-by.sync="sortOptions.sortBy"
          :sort-desc.sync="sortOptions.sortDesc" :options.sync="sortHandler" :items-per-page="50"
          :footer-props="footerProps" :custom-filter="filter" ref="issues_data_table"
          @current-items="updateSearchedItems" class="elevation-1">
          <template v-slot:top>
            <div class="d-flex align-start mx-5 pa-3">
              <h2 class="mr-auto">Issues</h2>
              <ExportButton v-if="isadministrator || issuperadministrator" :table-items="searchedItems"
                :table-type="'issues'" :export-message="'Export List'"></ExportButton>
              <Tooltip :message="tooltipMessage"></Tooltip>
            </div>
            <v-row class="d-flex align-center mx-5">
              <v-col cols="8">
                <v-text-field v-model="search" label="Search"></v-text-field>
              </v-col>
              <v-icon large v-if="search.length > 0" class="close-button" @click="search = ''">mdi-close</v-icon>
              <v-col cols="2">
                <v-checkbox :class="search.length > 0 && 'margin-left-10'" v-model="showclosed" label="Closed Issues"
                  @click="getitems(workspaceid)">
                </v-checkbox>
              </v-col>
              <v-col cols="2">
                <v-checkbox label="My issues" v-model="showUserIssues" @click="changeShowUserIssues()"></v-checkbox>
              </v-col>
            </v-row>
          </template>
          <template v-slot:body="{ items }">
            <tbody>
              <template v-for="(item, index) in items">
                <tr v-if="item != selectedItemActive" :key="index" @click="showdetailsdialog(item)"
                  class="issue-list table-hover">
                  <td>{{ item.type }}</td>
                  <td>{{ item.header }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.created | longdate }}</td>
                  <td>
                    <StatusButton :issue-item="item" @set-status="setStatus" @show-status-dialog="showstatusdialog">
                    </StatusButton>
                  </td>
                </tr>
                <tr v-else :key="index" @click="showdetailsdialog(item)" class="issue-list table-hover"
                  style="background-color: #eeeeee">
                  <td>{{ item.type }}</td>
                  <td>{{ item.header }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.created | longdate }}</td>
                  <td>
                    <StatusButton :issue-item="item" @set-status="setStatus" @show-status-dialog="showstatusdialog">
                    </StatusButton>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="detailsdialog" max-width="1400px" min-height="1000px" class="dialogbox" eager>
      <v-card>
        <div class="d-flex">
          <v-card-title>
            <span class="headline">{{ getReportType }} - {{ selecteditem.type }} -
              {{ selecteditem.name }} -
              {{ selecteditem.created | longdate }}</span>
          </v-card-title>
          <div class="ma-auto mr-4">
            <StatusButton :issue-item="selecteditem" @set-status="setStatus" @show-status-dialog="showstatusdialog">
            </StatusButton>
          </div>
        </div>
        <v-tabs background-color="transparent" color="black" left>
          <v-tab>Details</v-tab>
          <v-tab v-if="!!currentItemCoords && !!currentItemImageName" @click="showMapComponent">Map</v-tab>
          <v-tab-item v-for="n in 2" :key="n">
            <v-container fluid>
              <v-row v-if="n === 1">
                <v-col class="detail-text" style="overflow-y: auto">
                  <v-sheet elevation="5" rounded="lg">
                    <v-container>
                      <v-row v-for="(issueValue, issueKey) in selectedItemDetails" :key="issueKey" class="my-2"
                        no-gutters>
                        <div style="width: 120px">
                          <p v-if="issueKey != 'skip'" class="ma-0">
                            {{ issueKey }}:
                          </p>
                          <p v-else class="mb-6"></p>
                        </div>
                        <v-col align-self="start">
                          <p class="ma-0">{{ issueValue }}</p>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-sheet>
                </v-col>
                <v-col>
                  <a :href="pictureurl(selecteditem.picturename)" target="_blank"><img v-if="selecteditem.picturename"
                      class="issue-image" :src="pictureurl(selecteditem.picturename)" /></a>
                </v-col>
              </v-row>
              <v-row v-if="n === 2">
                <div class="map-area-general">
                  <MapComponent v-if="showMap" :qrId="-1" :name="currentItemImageName" :latLng="currentItemCoords" />
                </div>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile outlined class="mb-3" @click="detailsdialog = false"><v-icon small
              class="py-2 mr-1">mdi-close</v-icon> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { HTTP } from "@/plugins/backendAPI";
import { mapGetters } from "vuex";
import MapComponent from "../../components/MapComponent.vue";
import { EventBus } from "@/store/event-bus.js";
import Tooltip from "../../components/Tooltip.vue";
import StatusButton from "../../components/StatusButton.vue";
import ExportButton from "../../components/ExportButton.vue";

export default {
  props: {
    showClosed: {
      type: Boolean,
      default: false,
    },
    inventoryId: {
      type: String,
      default: "",
    },
  },
  components: {
    MapComponent,
    Tooltip,
    StatusButton,
    ExportButton,
  },
  data() {
    return {
      search: this.inventoryId,
      enableEscClear: true,
      showclosed: this.showClosed,
      showUserIssues: true,
      footerProps: { "items-per-page-options": [20, 50, 100] },
      headers: [
        { text: "Type", value: "type" },
        { text: "Header", value: "header", width: "45%" },
        { text: "Responsible", value: "name" },
        { text: "Created", value: "created" },
        { text: "Status", value: "status" },
      ],
      editedItem: {},
      tableItems: [],
      fullTableItems: [],
      searchedItems: [],
      inventorytypeItems: [],
      inventorytypeSelected: null,
      selecteditem: {},
      selectedItemDetails: {},
      selectedItemActive: {},
      tooltipMessage: [
        "Issues",
        "On this page, you can manage your issues/reports. Click on an issue to view its details. Change the status by clicking on the current status and selecting a new one. All contents of the listed issues are searchable, including the reporter's email address, comments, or device names.",
      ],
      detailsdialog: false,
      showToolTipDialog: false,

      sortHandler: {},
      sortOptions: {
        sortBy: [],
        sortDesc: [],
      },

      showMap: false,
      currentItemCoords: "",
      currentItemImageName: "",
    };
  },
  computed: {
    ...mapGetters(["sort", "workspaceid", "username", "isadministrator", "issuperadministrator"]),
    getReportType() {
      if (
        this.selecteditem.type === "Contact request" ||
        this.selecteditem.type === "Feedback"
      )
        return this.selecteditem.type;
      return "Fault report";
    },
  },
  created() {
    this.getitems(this.workspaceid);
    this.sortOptions = this.sort.issueSort || this.sortOptions;
    EventBus.$on("updateissues", (workspaceid) => {
      this.getitems(workspaceid);
    });
  },
  mounted() {
    document.addEventListener("keydown", this.clearSearchEsc);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.clearSearchEsc);
  },
  watch: {
    sortHandler: {
      deep: true,
      handler(obj) {
        const objSort = { sortBy: obj.sortBy, sortDesc: obj.sortDesc };

        if (JSON.stringify(objSort) !== JSON.stringify(this.sortOptions)) {
          const newObj = this.sort;
          newObj.issueSort = objSort;
          this.$store.dispatch("updateSort", newObj);
        }
      },
    },
    detailsdialog(val) {
      if (val) this.enableEscClear = false;
      if (!val) {
        this.showMap = false;
        this.currentItemCoords = "";
        this.currentItemImageName = "";
        setTimeout(() => {
          this.enableEscClear = true;
        }, 100);
      }
    },
  },
  methods: {
    changeShowUserIssues() {
      // eslint-disable-next-line no-console
      if (!this.showUserIssues) {
        this.tableItems = this.fullTableItems;
      }
      else {
        // eslint-disable-next-line no-console
        console.log(this.showUserIssues)
        this.getUserIssues();
      }
    },

    getUserIssues() {
      const userissues = []
      this.tableItems.forEach(row => {
        if (this.username == row.email) {
          userissues.push(row);
        }
      })
      this.fullTableItems = this.tableItems;
      this.tableItems = userissues;
    },
    updateSearchedItems() {
      this.$nextTick(() => {
        this.searchedItems = this.$refs.issues_data_table.$children[0].filteredItems
      });
    },
    getitems(workspaceid) {
      HTTP.get("/issues/get/" + this.showclosed + "/" + workspaceid).then(
        (response) => {
          this.tableItems = response.data;
          if (this.showUserIssues) {
            this.getUserIssues();
          }
        }
      );
    },
    showstatusdialog(item) {
      this.selecteditem = item;
    },
    showToolTip() {
      if (this.showToolTipDialog == true) {
        this.showToolTipDialog = false;
      } else {
        this.showToolTipDialog = true;
      }
    },
    getSelectedDetails(item) {
      this.itemDate = new Date(item.created);
      (this.differentItemDate = new Intl.DateTimeFormat("en-GB", {
        dateStyle: "short",
        timeStyle: "short",
      }).format(this.itemDate)),
        (this.selectedItemDetails = {
          "QR Category": item.qrCategory,
          "Inventory Type": item.inventoryType,
          Inventory: item.inventoryDescription,
          QRID: item.qrid,
          Created: this.differentItemDate,
          Description: item.description,
          Location: item.location,
          Responsable: item.name,
          skip: "",
          "Issuer e-mail": item.issuerEmail,
          Message: item.message,
          //"Created": `${this.itemDate.getFullYear()}-${this.itemDate.getMonth() + 1}-${this.itemDate.getDate()} ${this.itemDate.getHours()}:${this.itemDate.getMinutes()}`,
        });
    },
    showMapComponent(e) {
      const value = e.target.innerText;
      if (value === "MAP") {
        setTimeout(() => {
          this.showMap = true;
        }, 100);
      }
    },
    getStatus(status) {
      switch (status) {
        case 0:
          return "red";
        case 1:
          return "orange";
        case 2:
          return "green";
      }
    },
    getStatustext(status) {
      switch (status) {
        case 0:
          return "Waiting";
        case 1:
          return "Ongoing";
        case 2:
          return "Closed";
      }
    },
    setStatus(status, issueItem) {
      const previousStatus = issueItem.status;
      issueItem.status = status;

      HTTP.post("/issues/savestatus", {
        id: issueItem.id,
        type: issueItem.type,
        status: status,
      })
        .then(() => { })
        .catch((e) => {
          issueItem.status = previousStatus;
          this.$store.dispatch(
            "showError",
            "Error when setting status: " + e.response.data
          );
        });
    },
    showdetailsdialog(item) {
      this.selecteditem = item;
      this.getSelectedDetails(item);
      this.selectedItemActive = item;
      this.currentItemCoords = item.latLng === null ? "" : item.latLng;
      this.getImageName(item.imageId);
      this.detailsdialog = true;
    },
    getImageName(imageId) {
      HTTP.get("/image/checkifimageexist/" + imageId).then((result) => {
        this.currentItemImageName = result.data;
      });
    },
    pictureurl(picturename) {
      // eslint-disable-next-line
      return process.env.VUE_APP_APIURL + "/shared/bilder/" + picturename;
    },
    filter(value, search, item) {
      return Object.values(item).some((prop) => {
        return String(prop).toLowerCase().includes(search.toLowerCase());
      });
    },
    clearSearchEsc(e) {
      if (e.keyCode === 27) {
        if (this.detailsdialog) {
          this.detailsdialog = false;
          return;
        }
        if (this.enableEscClear) this.search = "";
      }
    },
    updateitems(workspaceid) {
      this.getitems(workspaceid);
    },
  },
};
</script>
