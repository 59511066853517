<template>
  <div class="adminbackground">
    <appbar></appbar>
    <v-row justify="center" class="mt-5">
      <v-col md="9" sm="12">
        <v-data-table dense :search="search" ref="inventorytypestable" disable-sort :headers="headers"
          :items="tableItems" :items-per-page="-1" :footer-props="footerProps" disable-pagination
          class="elevation-1 inventory-types-table">
          <template v-slot:top>
            <div class="d-flex align-start mx-5 pa-3">
              <h2 class="mr-auto">Inventory Type</h2>
              <Tooltip :message="tooltipMessage"></Tooltip>
            </div>
            <v-row class="d-flex align-center mx-5">
              <v-col cols=8>
                <v-text-field v-model="search" label="Search"></v-text-field>
              </v-col>
              <v-icon large v-if="search.length > 0" class="close-button" @click="search = ''">mdi-close</v-icon>
              <v-col cols=3>
                <v-btn :class="search.length > 0 && 'margin-left-10'" color="white" @click="newitem()">
                  <v-icon small class="py-2 mr-2">mdi-flip-to-front</v-icon> New
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon medium class="pa-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon medium class="pa-2" @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
          <template v-slot:item.sortorder>
            <v-icon medium class="py-2" style="cursor:move;">mdi-arrow-up-down</v-icon>
          </template>
        </v-data-table>
        <v-dialog v-model="dialog" max-width="800px" class="dialogbox">
          <v-card>
            <v-card-title>
              <span class="headline">{{ this.editedItem.id === 0 ? 'New' : 'Edit' }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form v-model="isFormValid" ref="form">
                  <v-row>
                    <v-col cols="6">
                      <v-text-field ref="description" required :rules="reqRules" v-model="editedItem.description"
                        label="Description"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-combobox required :rules="reqRules" item-value="id" :item-text="getItemTextName"
                        v-model="userSelected" :items="userItems" label="User"></v-combobox>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn tile outlined class="mb-3" @click="dialog = false"><v-icon small
                  class="py-2 mr-1">mdi-close</v-icon>
                Cancel</v-btn>
              <v-btn tile outlined class="mb-3 mr-3" @click="save()" :disabled="!isFormValid"><v-icon small
                  class="py-2 mr-1">mdi-content-save</v-icon> Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import { HTTP } from '@/plugins/backendAPI'
import Sortable from 'sortablejs'
import { mapGetters } from 'vuex'
import { EventBus } from '@/store/event-bus.js'
import Tooltip from '../../components/Tooltip.vue'

export default {
  components: { Tooltip },
  data() {
    return {
      tabeItemsChecked: false,
      dialog: false,
      enableEscClear: true,
      footerProps: { 'items-per-page-options': [1000, -1] },
      search: '',
      headers: [
        { text: 'Description', value: 'description' },
        { text: 'Notification reciever', value: 'fullname' },
        { text: 'Sort', value: 'sortorder', width: 130 },
        { text: 'Edit', value: 'action', sortable: false, width: 130 },

      ],
      editedItem: {},
      tableItems: [],
      userSelected: {},
      userItems: [],
      reqRules: [
        v => !!v || 'Field is required',
      ],
      isFormValid: false,

      sortHandler: {},
      sortOptions: {
        sortBy: [],
        sortDesc: [],
      },

      tooltipMessage: [
        "Inventory Type",
        'Create or edit inventory types. Examples of inventory types can include a chair, monitor, or keyboard. It\'s not a specific inventory but a broader description. This is also where you choose which administrator receives reports for each inventory type.'
      ],
    }
  },
  computed: {
    ...mapGetters([
      'sort',
      'workspaceid'
    ]),

  },
  watch: {
    dialog(val) {
      if (val) this.enableEscClear = false
      if (!val) {
        setTimeout(() => {
          this.enableEscClear = true
        }, 100)
      }
    },
    tableItems() {
      if (!this.tabeItemsChecked) {
        this.checkForDuplicateSortOrderValues()
        this.tabeItemsChecked = true
      }
    },
  },
  created() {
    this.getitems(this.workspaceid);
    EventBus.$on('updateinventorytype', workspaceid => {
      this.getitems(workspaceid);
    });

  },
  mounted() {
    document.addEventListener('keydown', this.clearSearchEsc)
    this.initDragAndDroptable()
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.clearSearchEsc)
  },
  methods: {
    initDragAndDroptable() {
      const table = document.querySelector("tbody")
      const self = this

      Sortable.create(table, {
        onStart(e) {
          self.$refs.inventorytypestable.$el.classList.add('dragging-table');
          e.from.children[e.oldIndex].classList.add('dragged-row')
        },
        onEnd(e) {
          const movedItem = self.tableItems.splice(e.oldIndex, 1)[0]
          self.tableItems.splice(e.newIndex, 0, movedItem)
          self.$refs.inventorytypestable.$el.classList.remove('dragging-table');
          e.from.children[e.newIndex].classList.remove('dragged-row')
          self.saveSortOrderToDb()
        }
      });
    },
    checkForDuplicateSortOrderValues() {
      const uniqueValues = new Set(this.tableItems.map(item => item.sortorder))
      const duplicateSortOrderValuesExist = uniqueValues.size < this.tableItems.length
      if (duplicateSortOrderValuesExist) this.saveSortOrderToDb()
    },
    saveSortOrderToDb() {
      if (this.tableItems.length === 0) return
      this.tableItems.forEach((item, index) => {
        item.sortorder = index
        item.workspaceid = this.workspaceid
      })
      HTTP.post('/inventory/saveinventorytypesortorder', this.tableItems, this.workspaceid).then(() => { }).catch(e => {
        self.$store.dispatch('showError', 'Error when updating sort order: ' + e.response.data)
      })
    },
    getitems(workspaceid) {
      HTTP.get('/inventory/getinventorytypes/' + workspaceid).then(response => {
        this.tableItems = response.data
      })
    },
    newitem() {
      if (this.workspaceid == -1) {
        this.$store.dispatch('showError', 'No workspace selected.<br/><br/>Please make sure you have created atleast one workspace and that it is selected.')
        return
      }
      this.editedItem = { description: null, id: 0, sortorder: this.tableItems.length, fullname: null, userid: null }
      this.editItem(this.editedItem)
    },
    editItem(item) {
      var self = this
      self.editedItem = Object.assign({}, item)
      HTTP.get('/inventory/getusers/' + this.workspaceid).then(response => {
        self.userItems = response.data
        self.userSelected = self.userItems.find(i => i.id === self.editedItem.userid)
        self.dialog = true
        if (this.$refs.form) this.$refs.form.resetValidation()
        setTimeout(() => {
          self.$refs.description.focus()
        }, 200)
      })
    },
    deleteItem(item) {
      const self = this
      this.$root.$confirm.open('Delete item', 'Are you sure?', { color: 'red' }).then((response) => {
        if (response) {
          HTTP.post('/inventory/deleteinventorytype', { id: item.id }).then(() => {
            self.tableItems.splice(self.tableItems.indexOf(item), 1)
            self.saveSortOrderToDb()
          }).catch(e => {
            self.$store.dispatch('showError', e.response.data)
          })
        }
      })
    },
    save() {
      var self = this
      self.editedItem.userid = self.userSelected.id;
      self.editedItem.fullname = self.userSelected.firstname + ' ' + self.userSelected.lastname;
      self.editedItem.sortorder = parseInt(self.editedItem.sortorder);
      self.editedItem.workspaceid = this.workspaceid;
      HTTP.post('/inventory/saveinventorytype', self.editedItem).then(response => {
        if (self.editedItem.id > 0) {
          var editedIndex = self.tableItems.findIndex(q => q.id === self.editedItem.id);
          self.tableItems[editedIndex].description = self.editedItem.description;
          self.tableItems[editedIndex].fullname = self.editedItem.fullname;
          self.tableItems[editedIndex].userid = self.editedItem.userid;
        } else {
          self.editedItem.id = response.data;
          self.tableItems.push(this.editedItem);
        }
        self.dialog = false;
      }).catch(e => {
        self.$store.dispatch('showError', 'Error when saving: ' + e.response.data);
      })
    },
    getItemTextName(item) {
      return `${item.firstname} ${item.lastname}`;
    },
    clearSearchEsc(e) {
      if (e.keyCode === 27 && this.enableEscClear) {
        this.search = ''
      }
    },
  }
}
</script>
