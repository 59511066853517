<template>
  <v-main>
    <v-container>
      <v-layout align-center justify-center mt-5>
        <v-flex xs12 sm8 md5 class="login-recover-window">
          <p class="additional-login-info" v-if="fromRoute !== undefined">Login to edit/register QR code: {{
            fromRoute.params.workspaceid === undefined ? fromRoute.params.qrstring : fromRoute.params.qrworkspaceid }}
          </p>
          <v-card class="elevation-12">
            <v-card-text class="">
              <v-form>
                <div class="py-8 text-center"><img src="./../assets/service-me-logo.png" class="biglogo" /></div>
                <v-text-field v-model="userinfo.email" label="Email" name="login" prepend-icon="mdi-account" type="text"
                  v-on:keyup="checkreturn"></v-text-field>

                <v-text-field v-model="userinfo.password" id="password" label="Password" name="password"
                  prepend-icon="mdi-lock" type="password" autocomplete="on" v-on:keyup="checkreturn"></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions class="login-actions">
              <v-btn text @click="recoverpassword()">Recover password</v-btn>
              <v-spacer></v-spacer>
              <v-btn class="ma-2 adminlargebutton" @click="login()">Login</v-btn>
            </v-card-actions>
            <v-progress-linear :active="loading" :indeterminate="loading" height="6" absolute bottom
              color="blue accent-2"></v-progress-linear>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import axios from 'axios'
import { HTTP } from '@/plugins/backendAPI'

export default {
  props: ['fromRoute'],
  name: 'Login',
  data() {
    return {
      userinfo: {
        email: '',
        password: ''
      },
      loading: false,
      prevRoute: null,
    }
  },
  methods: {
    checkreturn(e) {
      if (e.keyCode === 13) {
        this.login()
      }
    },
    login() {
      this.loading = true
      var self = this
      var params = 'username=' + this.userinfo.email + '&password=' + encodeURIComponent(this.userinfo.password)
      delete HTTP.defaults.headers.common['Authorization']
      // eslint-disable-next-line
      axios({ method: 'post', url: process.env.VUE_APP_APIURL + '/token', data: params, headers: { 'accept': 'application/json', 'content-type': 'application/x-www-form-urlencoded' } })
        .then(response => {
          self.loginSuccessful(response)
        })
        .catch(ex => {
          self.loginFailed(ex)
        })
    },
    loginSuccessful(req) {
      if (!req.data) {
        this.loginFailed()
        return
      }
      if (HTTP.defaults.headers.common['Authorization'] === undefined) {
        HTTP.defaults.headers.common['Authorization'] = 'Bearer ' + req.data
      }
      HTTP.post('/account/getkund', { email: this.userinfo.email })
        .then((response) => {
          this.$store.dispatch('saveLegacyWorkspaceId', response.data.legacyId);
          this.$store.dispatch('saveOrganizationId', response.data.user.organizationId);
          this.$store.dispatch('saveWorkspaceId', response.data.user.workspaceId);
          this.$store.dispatch('saveAuthorization', { username: this.userinfo.email, isauthorized: true, bearertoken: req.data, isadministrator: response.data.user.isAdministrator, issuperadministrator: response.data.user.isSuperAdministrator });
          if (this.fromRoute !== undefined) {
            this.$router.push({ name: this.fromRoute.name, params: this.fromRoute.params })
          }
          else {
            this.$router.push({ path: '/' })
          }
          this.loading = false
        })
        .catch(e => {
          this.$store.dispatch('showError', e.message)
          this.loading = false
        })
    },
    loginFailed() {
      this.loading = false
      this.$store.dispatch('showError', 'Check username and/or password')
      this.$store.dispatch('deleteAuthorization')
    },
    recoverpassword() {
      this.$router.push({ path: '/recoverpassword' })
    }
  }
}

</script>

<style scoped lang="css">
.additional-login-info {
  margin-bottom: 25px;
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bolder;
  color: lightcoral;
}
</style>
