<template>
<div class="px-5 pt-5">
    <div class="d-flex justify-space-between align-center">
        <div class="header">{{ header }}</div>
        <img src="./../assets/mobilelogo-mindflower.png" height="60">
    </div>
</div>
</template>

<script>
export default {
  props: ['header'],
  data() {
    return {
    }
  },
}
</script>
