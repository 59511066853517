<template>
  <div v-if="checkDone">
    <div v-if="!isAuthorized" class="appbackground">
      <v-container fill-height fluid>
        <v-row align="center" justify="center">
          <v-col align="center">
            <h3>Your account is not authorized to edit/register in this workspace.</h3>
            <v-btn class="adminbutton mt-8" to="/logout"><v-icon class="pa-2">mdi-logout</v-icon>Log out</v-btn>
          </v-col>
        </v-row>

      </v-container>
    </div>
    <div v-else class="appbackground">
      <v-progress-linear :active="loading" indeterminate color="blue"></v-progress-linear>
      <mobileappbar :header=header></mobileappbar>
      <div class="navtext pt-0 ml-5 mb-5">/ {{ isEditMode ? `${qrname} / Edit` : `New QR - ID: ${qrstring === undefined ? qrworkspaceid : qrstring} / Register` }}</div>
      <div class="mobile-view">
        <v-container class="px-12">
          <div class="infotext text-center mb-5">{{ isEditMode ? "Edit" : "Register a" }} QR-code by<br />filling out the
            form below.</div>

          <v-form v-model="isQrFormValid">

            <v-select required :rules="requiredRules" :items="qrCategories" item-text="description" item-value="id"
              label="Select type" v-model="newQrCategoryId" solo></v-select>

            <div v-if="newQrCategoryId !== null">

              <v-text-field required :rules="requiredRules" label="* Description" v-model="newQr.description"
                outlined></v-text-field>
              <div class="map-icon-mobile-container">
                <v-combobox v-model="selectedLocation" :items="locations" item-value="id" item-text="name" label="Location"
                  outlined></v-combobox>
                <div class="map-icon-mobile">
                  <v-icon large v-if="selectedLocation && selectedLocation.imageId"
                    :class="newQr.latLng && 'map-marker-selected'" @click="mapDialog = true">
                    mdi-map-marker-radius
                  </v-icon>
                </div>
              </div>
              <v-text-field label="Location description" v-model="newQr.position" outlined></v-text-field>

              <v-dialog fullscreen v-model="mapDialog">
                <div class="map-area-mobile">
                  <MapComponent v-if="selectedLocation != null && selectedLocation.imageName && mapDialog" :qrId="newQr.id"
                    :name="selectedLocation.imageName" :latLng="newQr.latLng" :mobileEdit="true"
                    @updateLocation="updateLocationFromChild" />

                  <div class="mobile-map-buttons">
                    <v-btn tile outlined class="mb-3 mr-2 mobile-map-button" @click="clearCoordinates"><v-icon small
                        class="py-2 mr-1">mdi-close</v-icon> Clear</v-btn>
                    <v-btn tile outlined class="mb-3 mr-3 mobile-map-button" @click="mapDialog = false"><v-icon small
                        class="py-2 mr-1">mdi-close</v-icon> Close</v-btn>
                  </div>
                </div>
              </v-dialog>


              <div>
                <div v-for="item in getForms" :key="item.name" class="switch-table-row">
                  <div>{{ item.description }}</div>
                  <v-switch @click="switchActive(item)" v-model="item.isActive" dense class="switch-toggle"></v-switch>
                </div>
              </div>

              <div class="newitem px-3 mb-5 pt-1" v-for="(item, index) in newQrItems" v-bind:key="index">
                <v-icon class="remove-btn" @click="removeItem(index)">mdi-close-circle-outline</v-icon>
                <v-select required :rules="requiredRules" :items="inventoryItems" item-text="description" item-value="id"
                  label="* Item" v-model="item.inventoryId"></v-select>
                <v-text-field label="Custom Type Description" v-model="item.aliasTypeDescription"></v-text-field>
              </div>

              <v-btn @click="addItem()" class="addbutton my-5" dark color="grey">
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </div>
          </v-form>
        </v-container>

        <v-row justify="center">
          <v-btn class="smallbutton mb-7 me-5" @click="$router.go(-1)" :loading="sending" :disabled="sending">{{ isEditMode
            ? "Back" : "Reset" }}</v-btn>
          <v-btn class="smallbutton mb-7" @click="save()" :loading="sending" :disabled="!isQrFormValid">Save</v-btn>

        </v-row>
      </div>
    </div>
  </div>
</template>

<script>

import { HTTP } from '@/plugins/backendAPI'
import { mapGetters } from 'vuex'
import MapComponent from '../components/MapComponent.vue'

const contactRequest = 'Contact request'
const feedback = 'Feedback'

export default {
  props: [
    'qrname',
    'qrstring',
    'workspaceid',
    'qrworkspaceid',
    'isEditMode'
  ],
  components: {
    MapComponent,
  },
  computed: {
    ...mapGetters([
      'username'
    ]),
    getForms() {
      return [
        { description: contactRequest, isActive: this.newQr.isContactRequestActive ? 1 : 0 },
        { description: feedback, isActive: this.newQr.isFeedbackActive ? 1 : 0 },
      ]
    }
  },
  data() {
    return {
      qrCategories: [],
      newQrCategoryId: null,
      localpicture: {},
      saving: false,
      loading: false,
      sending: false,
      isQrFormValid: false,
      inventoryItems: null,
      show: true,
      header: this.isEditMode ? "Edit QR" : "Register QR",

      formHeaders: [
        { text: 'Description', value: 'description', sortable: false },
        { text: 'Active', value: 'isActive', sortable: false, width: 150 },
      ],
      requiredRules: [
        v => !!v || 'Field is required',
      ],

      newQr: {
        id: 0,
        description: null,
        workspaceId: this.workspaceid,
        qrWorkspaceId:  this.qrworkspaceid,
        qrCategoryId: null,
        qrString: this.qrstring,
        latLng: null,
        isContactRequestActive: false,
        isFeedbackActive: false,
      },

      newQrItems: [],
      locations: [],
      selectedLocation: {},

      editQrId: null,
      mapDialog: false,

      isAuthorized: null,
      checkDone: false
    }
  },
  created() {
    HTTP.get(`/users/validateuseragainstworkspace/${this.username}/${this.workspaceid}`).then(response => {
      this.isAuthorized = response.data;
      this.checkDone = true;
    })
    
    if (!this.isEditMode) {
      HTTP.get(`/mobil/checkifqrexists/${this.qrstring}/${this.workspaceid}/${this.qrworkspaceid}`).then(response => {
        if (response.data.isFound) {
          this.$router.push({ name: 'Mobile', params: { workspaceid: this.workspaceid, qrworkspaceid: this.qrworkspaceid } })
        }
      })
      if (this.qrstring !== undefined){
        HTTP.get(`/qr/getqrworkspaceid/${this.workspaceid}`).then(response => {
          this.newQr.qrWorkspaceId = response.data;
        })
      }
      this.getLocations()
    } else {
      HTTP.get(`/qr/getqrid/${this.workspaceid}/${this.qrworkspaceid}`).then(response => {
        this.editQrId = response.data
        this.getQrData()
      })
    }
    HTTP.get(`/mobil/getqrcategories/${this.workspaceid}`).then(response => {
      this.qrCategories = response.data
    })
  },
  watch: {
    newQrCategoryId(newVal, oldVal) {
      if (!newVal) return
      const isUncategorized = this.qrCategories.find(item => item.id === newVal).description === 'Uncategorized'
      if (newVal === this.newQr.qrCategoryId) {
        if (!this.inventoryItems) {
          this.getInventoryByType(newVal)
        }
        return
      }

      if (oldVal && this.newQrItems.length > 0 && !isUncategorized) {
        this.$root.$confirm.open('Change QR Category', 'Are you sure? All current items will be removed.').then((response) => {
          if (response) {
            this.changeQrCategory(newVal)
          } else {
            this.newQrCategoryId = oldVal
          }
        })
      } else {
        this.changeQrCategory(newVal)
      }
    },
  },
  methods: {
    addItem() {
      this.newQrItems.push({ id: -1 });
    },
    removeItem(index) {
      this.newQrItems.splice(index, 1)
    },
    getLocations() {
      HTTP.get(`/location/get/${this.workspaceid}`).then(response => {
        this.locations = response.data
        this.selectedLocation = null
      })
    },
    getInventoryByType(qrCategoryId) {
      var self = this
      const isUncategorized = self.newQr.qrCategoryDescription === 'Uncategorized'
      HTTP.get(`/qr/getinventory/${qrCategoryId}/${isUncategorized}/${self.workspaceid}`)
        .then(response => {
          self.inventoryItems = response.data
        })
    },
    changeQrCategory(newVal) {
      const newQrCategory = this.qrCategories.find(item => item.id === newVal).description
      if (newQrCategory !== 'Uncategorized') this.newQrItems = []
      this.newQr.qrCategoryId = newVal
      this.newQr.qrCategoryDescription = newQrCategory
      this.getInventoryByType(newVal)
    },
    save() {
      var self = this

      if (self.selectedLocation) {
        if (self.selectedLocation.id) {
          self.newQr.newLocation = null
          self.newQr.locationId = self.selectedLocation.id
        } else {
          self.newQr.locationId = null
          self.newQr.newLocation = self.selectedLocation
        }
      } else {
        self.newQr.locationId = null
      }

      if (this.isEditMode) {
        HTTP.post('/qr/save', { qr: self.newQr, qrDetails: self.newQrItems, qrCategories: null }).then(() => {
          this.$router.push({ name: 'MobileEditSuccess', params: { isEditMode: this.isEditMode } });
        }).catch(() => {
          this.$store.dispatch('showError', 'Your changes could not be saved.')
        })
      } else {
        HTTP.get(`/mobil/checkifqrexists/${this.qrstring}/${this.workspaceid}/${this.qrworkspaceid}`).then(response => {
          if (!response.data.isFound) {
            var self = this
            HTTP.post('/qr/save', { qr: self.newQr, qrDetails: self.newQrItems, qrCategories: null }).then(() => {
              this.$router.push({ name: 'MobileEditSuccess', params: { isEditMode: this.isEditMode } });
            }).catch(() => {
              this.$store.dispatch('showError', 'Your changes could not be saved.')
            })
          }
          else {
            this.$store.dispatch('showError', 'Your QRID is already in use, please scan QR code again.')
          }
        }).catch(() => {
          this.$store.dispatch('showError', 'Your changes could not be saved.')
        })
      }
    },
    switchActive(item) {
      const typeOfSwitch = item.description
      if (typeOfSwitch === contactRequest) this.newQr.isContactRequestActive = item.isActive
      if (typeOfSwitch === feedback) this.newQr.isFeedbackActive = item.isActive
    },
    getQrData() {
      var self = this;
      HTTP.get('/qr/edit/' + self.editQrId).then(response => {
        self.newQrCategoryId = response.data.qr.qrCategoryId
        self.newQr.workspaceId = response.data.qr.workspaceId
        self.newQr.qrWorkspaceId = response.data.qr.qrWorkspaceId
        self.newQr.id = response.data.qr.id
        self.newQr.description = response.data.qr.description
        self.newQr.qrCategoryDescription = response.data.qr.qrCategoryDescription
        self.newQr.qrCategoryId = response.data.qr.qrCategoryId
        self.newQr.qrString = response.data.qr.qrString
        self.newQr.position = response.data.qr.position
        self.newQr.latLng = response.data.qr.latLng
        self.locations = response.data.locations
        self.selectedLocation = self.locations.find(l => l.id === response.data.qr.locationId) || null
        self.newQr.isContactRequestActive = response.data.qr.isContactRequestActive
        self.newQr.isFeedbackActive = response.data.qr.isFeedbackActive

        self.newQrItems = response.data.qrDetails
      })
    },
    updateLocationFromChild(value) {
      this.newQr.latLng = value
    },
    clearCoordinates() {
      this.newQr.latLng = null
    },
  }
}
</script>

<style scoped>
.remove-btn {
  display: block !important;
  margin-left: auto !important;
  margin-right: 0 !important;
  border-radius: 50%;
  cursor: pointer;
}

.switch-table-row {
  display: flex;
  justify-content: space-between;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #3D3D3D;
}

.switch-toggle {
  margin: 0 !important;
  padding: 0 !important;
}

.mobile-view {
  max-width: 600px;
  margin: auto auto;
}

.mobile-map-buttons {
  position: absolute;
  z-index: 999;
  right: 0;
  bottom: 0;
}

.mobile-map-button {
  background-color: #fff;
  border: 2px solid #ccc;
  width: 90px;
}
</style>