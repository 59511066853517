import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    username: localStorage.username,
    isauthorized: localStorage.isauthorized,
    isadministrator: localStorage.isadministrator,
    issuperadministrator: localStorage.issuperadministrator,
    bearertoken: localStorage.bearertoken,
    errordialog: false,
    errormessage: null,
    workingdialog: false,
    sort : localStorage.sort,
    organizationid: localStorage.organizationid,
    workspaceid: localStorage.workspaceid,
    legacyworkspaceid: localStorage.legacyworkspaceid,
    organizationitems: [],
    workspaceitems: []
  },
  getters: {
    username: state => {
      return state.username;
    },
    isauthorized: state => {
      return state.isauthorized;
    },
    isadministrator: state => {
      return state.isadministrator === true ? true :
        state.isadministrator === 'true' ? true : false;
    },
    issuperadministrator: state => {
      return state.issuperadministrator === true ? true :
        state.issuperadministrator === 'true' ? true : false;
    },
    bearertoken: state => {
      return state.bearertoken;
    },
    errordialog: state => {
      return state.errordialog;
    },
    errormessage: state => {
      return state.errormessage;
    },
    workingdialog: state => {
      return state.workingdialog;
    },
    sort: state => {
      return JSON.parse(state.sort);
    },
    organizationid: state => {
      if (state.organizationid == undefined) {
        localStorage.organizationid = 1;
        return localStorage.organizationid;
      }
      return state.organizationid;
    },
    workspaceid: state => {
      if (state.workspaceid == undefined) {
        localStorage.workspaceid = 1;
        return localStorage.workspaceid;
      }
      return state.workspaceid;
    },
    legacyworkspaceid: state => {
      return state.legacyworkspaceid;
    },
    islegacy: state => {
      return parseInt(state.legacyworkspaceid) === parseInt(state.workspaceid);
    },
    organizationitems: state => {
      return state.organizationitems;
    },
    workspaceitems: state => {
      return state.workspaceitems;
    },
    
  },
  mutations: {
    initStorage (state) {
      if (localStorage.username === 'undefined') {
        localStorage.username = state.username === undefined ? '' : state.username
      }
      if (localStorage.isauthorized === 'undefined') {
        localStorage.isauthorized = state.isauthorized === undefined ? '' : state.isauthorized
      }
      if (localStorage.organizationid === 'undefined') {
        localStorage.organizationid = state.organizationid === undefined ? '' : state.organizationid
      }
      if (localStorage.workspaceid === 'undefined') {
        localStorage.workspaceid = state.workspaceid === undefined ? '' : state.workspaceid
      }
      if (localStorage.legacyworkspaceid === 'undefined') {
        localStorage.legacyworkspaceid = state.legacyworkspaceid === undefined ? '' : state.legacyworkspaceid
      }
      if (localStorage.bearertoken === 'undefined') {
        localStorage.bearertoken = state.bearertoken === undefined ? '' : state.bearertoken
      }
      if (localStorage.sort === undefined) {
        const sortObj = { sortBy: [""], sortDesc: [false] }

        state.sort = JSON.stringify({
          issueSort: { sortBy: ["created"], sortDesc: [true] },
          qrSort: sortObj,
          inventorySort: sortObj,
          statisticSort: sortObj,
          userSort: sortObj,
          qrCategorySort: sortObj,
          locationSort: sortObj
        })

        localStorage.sort = JSON.stringify({
          issueSort: { sortBy: ["created"], sortDesc: [true] },
          qrSort: sortObj,
          inventorySort: sortObj,
          statisticSort: sortObj,
          userSort: sortObj,
          qrCategorySort: sortObj,
          locationSort: sortObj
        })
      }
    },
    saveAuthorization (state, authinfo) {
      state.username = authinfo.username;
      state.isauthorized = authinfo.isauthorized;
      state.bearertoken = authinfo.bearertoken;
      state.isadministrator = authinfo.isadministrator;
      state.issuperadministrator = authinfo.issuperadministrator;
  
      localStorage.username = authinfo.username;
      localStorage.isauthorized = authinfo.isauthorized;
      localStorage.bearertoken = authinfo.bearertoken;
      localStorage.isadministrator = authinfo.isadministrator;
      localStorage.issuperadministrator = authinfo.issuperadministrator;
    },
    saveOrganizationId (state, organizationid) {
      state.organizationid = organizationid;
      localStorage.organizationid = organizationid;
    },
    saveWorkspaceId (state, workspaceid) {
      state.workspaceid = workspaceid;
      localStorage.workspaceid = workspaceid;
    },
    saveLegacyWorkspaceId (state, legacyworkspaceid) {
      state.legacyworkspaceid = legacyworkspaceid;
      localStorage.legacyworkspaceid = legacyworkspaceid;
    },
    deleteAuthorization (state) {
      state.username = null
      state.isauthorized = null
      state.bearertoken = null
  
      delete localStorage.username
      delete localStorage.isauthorized
      delete localStorage.bearertoken
    },
    showError (state, errormessage) {
      state.errormessage = errormessage
      state.errordialog = true
    },
    clearError (state) {
      state.errordialog = false
      state.errormessage = null
    },
    showWorkingStatus (state) {
      state.workingdialog = true
    },
    clearWorkingStatus (state) {
      state.workingdialog = false
    },
    updateSort (state, sortInfo) {
      state.sort = JSON.stringify(sortInfo)
      localStorage.sort = JSON.stringify(sortInfo)
    },
    addOrganizationitem(state, item) {
      state.organizationitems.push(item);
    },
    deleteOrganizationitem(state, item) {
      var itemindex = state.organizationitems.findIndex(oi => oi.id === item.id);
      state.organizationitems.splice(itemindex, 1)
    },
    clearOrganizationitems(state) {
      state.organizationitems = []
    },
    addWorkspaceitem(state, item) {
      state.workspaceitems.push(item);
    },
    deleteWorkspaceitem(state, item) {
      var itemindex = state.workspaceitems.findIndex(w => w.id === item.id);
      state.workspaceitems.splice(itemindex, 1)
    },
    clearWorkspaceitems(state) {
      state.workspaceitems = []
    }
  },
  actions: {
    initStorage ({ commit }) {
      commit('initStorage', null)
    },
    saveAuthorization ({ commit }, authinfo) {
      commit('saveAuthorization', authinfo)
    },
    saveOrganizationId ({ commit }, organizationid) {
      commit('saveOrganizationId', organizationid)
    },
    saveWorkspaceId ({ commit }, workspaceid) {
      commit('saveWorkspaceId', workspaceid)
    },
    saveLegacyWorkspaceId ({ commit }, legacyworkspaceid) {
      commit('saveLegacyWorkspaceId', legacyworkspaceid)
    },
    addOrganizationitem({commit}, item) {
      commit('addOrganizationitem', item)
    },
    deleteOrganizationitem({commit}, item) {
      commit('deleteOrganizationitem', item)
    },
    clearOrganizationitems({ commit }) {
      commit('clearOrganizationitems', null)
    },
    addWorkspaceitem({commit}, item) {
      commit('addWorkspaceitem', item)
    },
    deleteWorkspaceitem({commit}, item) {
      commit('deleteWorkspaceitem', item)
    },
    clearWorkspaceitems({ commit }) {
      commit('clearWorkspaceitems', null)
    },
    deleteAuthorization ({ commit }) {
      commit('deleteAuthorization', null)
    },
    showError ({ commit }, errormessage) {
      commit('showError', errormessage)
    },
    clearError ({ commit }) {
      commit('clearError', null)
    },
    showWorkingStatus ({ commit }) {
      commit('showWorkingStatus', null)
    },
    clearWorkingStatus ({ commit }) {
      commit('clearWorkingStatus', null)
    },
    updateSort ({ commit }, sortInfo) {
      commit('updateSort', sortInfo)
    }
  },
  modules: {
  }
})
