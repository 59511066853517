<template>
  <div class="appbackground">
    <v-progress-linear :active="loading" indeterminate color="blue"></v-progress-linear>
    <mobileappbar header="Fault report"></mobileappbar>
    <div class="navtext pt-0 ml-5" :class="openReports.length === 0 && 'mb-5'">/ {{ qrname }}</div>
    <div v-if="openReports.length > 0" class="reported-alert ml-5 navtext mb-5">
      Item reported: {{ openReports[0].dateReported.split('T')[0] }}
      <br />
      Status: {{ getStatustext(openReports[0].status) }}
    </div>

    <div class="center-obj">
      <div v-if="condition" class="mb-5" :class="panel.length > 0 ? 'instruction-wrapper-open' : 'instruction-wrapper'">
        <v-expansion-panels multiple v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="instruction-header">{{ instructionHeader || 'Instructions' }}</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-html="instruction"></div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>

    <div class="infotext text-center mb-5">Take a picture and/or<br />write a message</div>
    <v-row justify="center" id="errorpic">
      <div class="mobileUpload" :class="[hasImage ? 'crop' : 'nocrop']">
        <input id="single-file-input" @change="singleFileChange($event)" type="file" name="photos" accept="image/*"
          capture="environment" style="display:none;">
        <div :class="[hasImage ? 'fakebuttonimageloaded' : '', 'fakebutton']" @click="uploadReplacementImage">
          <v-icon class="buttonicon">mdi-camera</v-icon>
        </div>
        <img v-if="hasImage" class="image-preview" :src="imgPreviewSrc" width="400">
      </div>
    </v-row>

    <v-form ref="contactform" v-model="isFormValid">
      <v-row justify="center" class="mt-7">
        <v-col align="center">
          <v-textarea required :rules="requiredRules" v-model="message" outlined class="messagearea"
            label="* Message"></v-textarea>
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-1">
        <span class="messagearea emailtext">Enter your email address to get feedback:</span>
      </v-row>
      <v-row justify="center" class="mt-1">
        <v-text-field v-model="email" validate-on-blur :rules="emailRules" class="messagearea smalltext" type="email"
          solo></v-text-field>
      </v-row>
    </v-form>

    <v-row justify="center">
      <v-btn class="smallbutton mb-5" @click="$router.go(-1)" :loading="sending" :disabled="sending">Back</v-btn>
      <v-btn class="smallbutton ml-3 mb-5" @click="save()" :loading="sending" :disabled="!isFormValid">Send
        <template v-slot:loader>
          <span>Sending...</span>
        </template>
      </v-btn>
    </v-row>
  </div>
</template>

<script>

import { HTTP } from '@/plugins/backendAPI'

const emailRegex = /^$|null|\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

export default {
  props: ['qrdetailid', 'workspaceid', 'qrworkspaceid'],
  data() {
    return {
      qrname: null,
      message: null,
      email: null,
      localpicture: {},
      imgPreviewSrc: null,
      saving: false,
      saved: false,
      loading: false,
      hasImage: false,
      sending: false,
      instruction: null,
      instructionHeader: null,
      openReports: [],
      panel: [],

      requiredRules: [
        v => !!v || 'Field is required',
      ],
      emailRules: [
        v => !!emailRegex.test(v) || 'Email is in incorrect format',
      ],
      isFormValid: false,
    }
  },
  created() {
    HTTP.get('/mobil/getinventorytype/' + parseInt(this.qrdetailid)).then(response => {
      this.qrname = response.data.qrDetailInfo.qrname
      this.instruction = response.data.qrDetailInfo.instruction
      this.instructionHeader = response.data.qrDetailInfo.instructionHeader
      this.openReports = response.data.openReports
    })
  },
  computed: {
    condition() {
      return this.instruction !== null &&
        this.instruction !== '' &&
        this.instruction !== '<p></p>' &&
        this.instruction !== '<h1></h1>' &&
        this.instruction !== '<h2></h2>' &&
        this.instruction !== '<h3></h3>';
    },
  },
  methods: {
    save() {
      if (!(JSON.stringify(this.localpicture) === '{}' && this.message === null)) {
        this.loading = true
        this.sending = true
        this.saveattachment()
      } else {
        this.$store.dispatch('showError', 'Enter message and/or take picture')
      }
    },
    uploadReplacementImage() {
      document.getElementById('single-file-input').click();
    },
    singleFileChange(e) {
      if (!e.target.files.length) {
        return;
      }
      this.hasImage = true;
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        this.imgPreviewSrc = reader.result
        this.localpicture = {
          filedata: this.imgPreviewSrc.replace('data:', '').replace(/^.+,/, ''),
          filename: file.name,
          filesize: file.size,
          filetype: file.type,
        }
      }
      reader.readAsDataURL(file);
    },
    saveattachment() {
      HTTP.post('/mobil/sendfelrapport', {
        qrdetailid: parseInt(this.qrdetailid),
        message: this.message,
        email: this.email,
        picture: this.localpicture,

      }).then(() => {
        this.loading = false
        this.$router.push({ path: '/mobilesent' })
      }).catch(e => {
        this.loading = false
        this.$store.dispatch('showError', e.response.data)
      })
    },
    getStatustext(status) {
      switch (status) {
        case 0:
          return "Waiting"
        case 1:
          return "Ongoing"
        case 2:
          return "Closed"
      }
    },
  }
}
</script>
<style>
#fileInput {
  display: none;
}

.instruction-header {
  font-weight: bold;
}

.instruction-wrapper {
  width: 250px;
  max-width: 700px;
}

.instruction-wrapper-open {
  width: 85%;
  max-width: 700px;
}

.center-obj {
  display: flex;
  flex-direction: column;
  place-items: center;
}
</style>
